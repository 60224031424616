import styled, { css } from 'styled-components';
import Media from '../../styled-components/media';

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  ${Media.xs`width: 576px;`}
  ${Media.sm`width: 768px;`}
  ${Media.md`width: 992px;`}
  ${Media.lg`width: 1200px;`}
`;

export const RowPanel = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ColPanel = styled.div`
  flex: 1;
`;
