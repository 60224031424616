import { action, makeObservable } from 'mobx';

class SupportDoashboard {
  constructor(api) {
    makeObservable(this, {
      getMessages: action,
      getConversationInfo: action,
      getConversationMember: action,
      getConversationHistory: action
    });

    this.api = api;
  }

  getMessages = (params, id) => this.api.get(`/cs/messages/${id}`, params);

  getConversationInfo = (id) => this.api.get(`/cs/messages/${id}/info`);

  getConversationMember = (id) => this.api.get(`/cs/messages/${id}/member`);

  getConversationHistory = (id) => this.api.get(`/cs/messages/${id}/history`);
}

export default SupportDoashboard;
