import styled from 'styled-components';

import AxlLoading from '../AxlLoading';

export const Container = styled.div`
    position: relative;

    &.loading {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
        }
    }
`;

export const Table = styled.table`
    width: 100%;
    border: 1px solid #DDD;
    border-collapse: collapse;
    border-spacing: 0px;
`;

export const TH = styled.th`
    padding: 15px;
    font-size: 12px;
    color: #bebfc0;
    font-family: 'AvenirNext';
    font-style: normal;
    text-transform: uppercase;
    font-weight: normal;
    position: relative;
    &.active {
        &:after {
            content: "";
            border-top: 3px solid #bebfc0;
            border-left: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-right: 3px solid transparent;
            display: inline-block;
            margin: 0 3px;
        }
        &.up {
            &:after {
                margin-bottom: 3px;
                transform: rotate(180deg);
            }
        }
        span {
            text-decoration: none !important;
        }
    }
    &.pointer {
        cursor: pointer;
        span {
            text-decoration: underline;
        }
    }
`;

export default {
    container: {
        width: '100%',
        border: 'solid 1px #ddd',
        borderCollapse: 'collapse',
        borderSpacing: '0px'
    },
    cell: {
        padding: '15px',
        fontSize: '12px',
        color: '#4a4a4a',
        fontFamily: 'AvenirNext',
        fontWeight: '500',
        fontStyle: 'normal'
    },
    row: {
        borderTop: 'solid 1px #ddd'
    },
    headerCell: {
        fontSize: '12px',
        color: '#bebfc0',
        fontFamily: 'AvenirNext',
        textTransform: 'uppercase',
        fontWeight: 'normal',
        fontStyle: 'normal'
    },
    LoadingContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: 30,
        height: 30,
        margin: 'auto',
        zIndex: 999
    }
}
