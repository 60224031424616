import styled from 'styled-components';

export const Container = styled.div`
  padding: 7.5px;
  border-radius: 6px;
  background-color: #f4f4f4;
  margin: 10px 0;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Title = styled.div`
  flex: 1;
`;
export const Arrow = styled.div`
  cursor: pointer;
`;
export const Inner = styled.div`
  padding: 5px 0;
`;

export const I = styled.i`
  font-size: 18px;
  color: #8d8d8d;
  line-height: 1;
`;

export default {}