import React from 'react';

import styles, * as E from './styles';

export default class AxlTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHover: false
    }
  }

  onMouseEnter = () => {this.setState({isHover: true}) }

  onMouseLeave = () => {this.setState({isHover: false}) }

  render() {
    return <E.Container
      onMouseEnter={this.onMouseEnter}
      onMouseLeave={this.onMouseLeave}
    >
      {this.state.isHover && <E.Inner>
        <E.InnerTitle>
          <E.Title>{this.props.title}</E.Title>
          <E.Arrow />
        </E.InnerTitle>
      </E.Inner>}
      <E.Child>{this.props.children}</E.Child>
    </E.Container>
  }
}
