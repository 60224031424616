import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #f4f3ff;
  text-align: left;
  position: absolute;
  width: 304px;
  height: 100%;
  top: 52px;
  left: -100%;
  z-index: 999;
  transition: all 0.3s ease 0s;
  &.active {
    left: 0;
  }
`;

export const InnerContainer = styled.div`
  position: relative;
`;

export const UnderList = styled.ul`
  list-style: none;
  padding-inline-start: 0;
  margin: 0;
`;

export const List = styled.li`
  font-family: "AvenirNext-Medium";
  color: #4a4a4a;
  border-bottom: 1px solid #deddeb;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  &:hover {
    background: #e3e2f7;
  }
  &.active {
    position: relative;
    &:after {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      margin: auto;
      background: #2a2444;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 12px;
      border-radius: 50%;
    }
  }
`;

export const BackgroundMenu = styled.div`
  background-color: rgba(0,0,0,0.2);
  position: fixed;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  z-index: 998;
  transition: all 0.3s ease 0s;
  &.active {
    left: 0;
  }
`;

export const Link = styled.a`
  display: block;
  padding: 20px 30px;
  font-family: "AvenirNext-Medium";
  color: #4a4a4a;
  border-bottom: 1px solid #deddeb;
  text-decoration: none;
`;
