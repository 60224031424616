import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';

// Components
import AxlButton from '../AxlButton'

// Styles
import { Container, Form, ImagePreview, Image, PreviewText, Inner, InputFile, Title } from './styles'

export default class AxlImageUploadPreView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: ''
    };
  }

  _handleSubmit(e) {
    e.preventDefault();
    console.log('handle uploading-', this.state.file);
    const {onUpload} = this.props;
    onUpload(this.state.file);
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    }

    reader.readAsDataURL(file)
  }

  render() {
    const {uploading} = this.props;
    let { imagePreviewUrl, file } = this.state;
    let imagePreview = null;
    if (imagePreviewUrl) {
      imagePreview = (<Image src={imagePreviewUrl} />);
    } else {
      imagePreview = (<PreviewText>{`Please select an Image for Preview`}</PreviewText>);
    }

    return <ThemeProvider theme={{type: this.props.theme ? this.props.theme : 'default'}}>
      <Container>
        <Title>{`Upload Image`}</Title>
        <Form onSubmit={(e)=>this._handleSubmit(e)}>
          <Inner>
            <ImagePreview>{imagePreview}</ImagePreview>
            <InputFile onChange={(e)=>this._handleImageChange(e)} />
          </Inner>
          {file && <AxlButton loading={uploading !== undefined ? uploading : false} compact bg={`blueGray`} onClick={(e)=>this._handleSubmit(e)}>{`Upload Image`}</AxlButton>}
        </Form>
      </Container>
    </ThemeProvider>
  }
}
