import React, { Component } from 'react';
import styles, { Logo } from './styles';
import logo from './logo.svg';

class AxlHeader extends Component {
    render() {
        return <Logo align={'middle'} src={logo} alt="logo" {...this.props} />
    }
}

export default AxlHeader