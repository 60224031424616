import styled from 'styled-components';
import { STATUS_COLOR_CODE, Colors } from '../../constants/Colors';

export const Container = styled.div`
    box-sizing: border-box;
    border-radius: 3px;
    line-height: 1.5em;
    font-size: 15px;
    box-shadow: 0px 0px 1px #888;
    min-height: 44px;
    margin: 10px 1px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    text-align: left;
    border-right: solid 6px;
    border-right-color: ${STATUS_COLOR_CODE.PENDING};
`;

export const Inner = styled.div`
  padding: 10px 5px 2px 44px;
  background-color: white;
`;

export const ContainerTag = styled.div`
  padding: 7px 15px;
  border-top: solid 1px #eaeaea;
  background-color: #f2f3f2;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Col = styled.div`
    flex: 1
`;

export const Logo = styled.div`
    position: absolute;
    top: 14px;
    left: 4px;
    width: 32px;
    height: 32px;
`;

export const Text = styled.div`
    font-family: 'AvenirNext-Medium';
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    color: #3b3b3b;
    margin-bottom: 5px;

    &.text-right { text-align: right; }
    &.text-center { text-align: center; }
    &.text-left { text-align: left; }
`;

export const SmallText = styled.span`
  font-family: 'AvenirNext-Medium';
  font-size: 10px;
  font-weight: 300;
  color: #3b3b3b;
  display: inline-block;
`;

export const DayText = styled.span`
  color: #55a;
`;

export const TimeZoneText = styled.span`
    font-family: 'AvenirNext-Bold';
    font-weight: normal;
`;

export const Name = styled.div`
    font-family: 'AvenirNext-Bold';
    font-size: 14px;
    font-weight: bold;
    color: #3b3b3b;
    margin-bottom: 5px;
`;

export const RibbonDeleted = styled.div`
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto;
    font-size: 9px;
    color: red;
    padding: 0px 8px;
    transform: rotate(45deg);
    border: 1px solid red;
    border-radius: 1px;
    height: 22px;
`;

export default {
    statuses: {
        PENDING: {
            borderRightColor: STATUS_COLOR_CODE.PENDING
        },
        IN_PROGRESS: {
            borderRightColor: STATUS_COLOR_CODE.IN_PROGRESS
        },
        SUCCEEDED: {
            borderRightColor: STATUS_COLOR_CODE.SUCCEEDED
        },
        FAILED: {
            borderRightColor: STATUS_COLOR_CODE.FAILED
        },
        DROP_OFF: {
            PENDING: {
                borderRightColor: STATUS_COLOR_CODE.PENDING
            },
            IN_PROGRESS: {
                borderRightColor: STATUS_COLOR_CODE.IN_PROGRESS
            },
            SUCCEEDED: {
                borderRightColor: STATUS_COLOR_CODE.SUCCEEDED
            },
            FAILED: {
                borderRightColor: STATUS_COLOR_CODE.FAILED
            },
            DISCARDED: {
                borderRightColor: '#aaa'
            }    
        },
        RETURN: {
            PENDING: {
                borderRightColor: Colors.lightGrey
            },
            IN_PROGRESS: {
                borderRightColor: Colors.lightGrey
            },
            SUCCEEDED: {
                borderRightColor: Colors.bluish
            },
            FAILED: {
                borderRightColor: '#222'
            },
            DISCARDED: {
                borderRightColor: '#aaa'
            }    
        }
    }
}
