import React, { Component, cloneElement } from 'react';

// Components
import { AxlModal, AxlButton } from '../../components/'

// Styles
import styles, * as E from './styles';

export default class AxlPopConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    }
    this.onOk       = this.onOk.bind(this);
    this.onCancel   = this.onCancel.bind(this);
    this.openModal  = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.isClose !== this.props.isClose && this.props.isClose) {
      this.closeModal();
    }
  }

  handleOnClick = (e, ...rest) => {
    this.openModal()
  }

  openModal = () => this.setState({showModal: true})

  closeModal = () => {
    this.setState({showModal: false});
  }

  onOk = () => {
    if(!this.props.disabled) {
      this.props.onOk && this.props.onOk()
      this.closeModal()
    }
  }

  onCancel = () => {
    this.props.onCancel && this.props.onCancel()
    this.closeModal()
    console.log('1')
  }

  onControl = () => {
    this.props.onControl && this.props.onControl()
  }


  render() {
    const { trigger, textFormat, titleFormat, children } = this.props;
    const { showModal } = this.state;
    const { disabled } = trigger.props;
    const props = {
      ...this.props,
      onOk: this.onOk,
      onCancel: this.onCancel,
      onControl: this.onControl,
      closeModal: this.closeModal,
      styleContainer: this.props.styleContainer || {}
    }

    return <span>{cloneElement(trigger, {
      onClick: this.handleOnClick
    })}
    {(showModal && !disabled) && (this.props.main ? <MainTheme {...props} /> : (
      this.props.secondary ? <SecondaryTheme {...props} /> : (
        this.props.third ? <ThirdTheme {...props} /> : (
          this.props.custom ? <CustomTheme {...props} /> : <DefaultTheme {...props} />
        )
      )
    ))}</span>
  }
}

class DefaultTheme extends React.Component {
  render() {
    const styleContainer = Object.assign({}, styles.container, this.props.styleContainer);

    return <AxlModal onClose={this.props.closeModal} style={styleContainer} theme={'default'}>
      <E.Title>{cloneElement(this.props.titleFormat)}</E.Title>
      <E.Text>{cloneElement(this.props.textFormat)}</E.Text>
      {this.props.children}
      <E.Control>
        { this.props.cancelText && <AxlButton tiny bg={`gray`} style={styles.buttonControl} onClick={this.props.onCancel}>{this.props.cancelText}</AxlButton> }
        { this.props.okText && <AxlButton tiny bg={`pink`} style={styles.buttonControl} onClick={this.props.onOk}>{this.props.okText}</AxlButton> }
        { this.props.okComponent && cloneElement(this.props.okComponent, {onClick: this.props.onOk}) }
        { this.props.cancleComponent && cloneElement(this.props.cancleComponent, {onClick: this.props.onCancel}) }
      </E.Control>
    </AxlModal>
  }
}

class SecondaryTheme extends React.Component {
  render() {
    const styleContainer = Object.assign({}, styles.container, this.props.styleContainer);

    return <AxlModal onClose={this.props.closeModal} style={styleContainer} theme={'secondary'}>
      <E.Title>{cloneElement(this.props.titleFormat)}</E.Title>
      <E.Text>{cloneElement(this.props.textFormat)}</E.Text>
      {this.props.children}
      <E.Control>
        { this.props.okText && <AxlButton style={styles.buttonControl} onClick={this.props.onOk}>{this.props.okText}</AxlButton> }
        { this.props.cancelText && <AxlButton bg={`black`} style={styles.buttonControl} onClick={this.props.onCancel}>{this.props.cancelText}</AxlButton> }
        { this.props.okComponent && cloneElement(this.props.okComponent, {onClick: this.props.onOk}) }
        { this.props.cancleComponent && cloneElement(this.props.cancleComponent, {onClick: this.props.onCancel}) }
      </E.Control>
    </AxlModal>
  }
}

class MainTheme extends React.Component {
  render() {
    const styleContainer = Object.assign({}, styles.container, this.props.styleContainer);

    return <AxlModal onClose={this.props.closeModal} style={styleContainer} theme={'default'}>
      <E.TitleMain>{cloneElement(this.props.titleFormat)}</E.TitleMain>
      <E.TextMain>{cloneElement(this.props.textFormat)}</E.TextMain>
      {!this.props.lastChild && this.props.children}
      <E.Control>
        { this.props.okText && <AxlButton compact style={styles.buttonControl} onClick={this.props.onOk}>{this.props.okText}</AxlButton> }
        { this.props.controlText && <AxlButton compact bg={`pink`} style={styles.buttonControl} onClick={this.props.onControl}>{this.props.controlText}</AxlButton> }
        { this.props.cancelText && <AxlButton compact bg={`red`} style={styles.buttonControl} onClick={this.props.onCancel}>{this.props.cancelText}</AxlButton> }
        { this.props.okComponent && cloneElement(this.props.okComponent, {onClick: this.props.onOk}) }
        { this.props.cancleComponent && cloneElement(this.props.cancleComponent, {onClick: this.props.onCancel}) }
      </E.Control>
      {this.props.lastChild && this.props.children}
    </AxlModal>
  }
}

class ThirdTheme extends React.Component {
  render() {
    const styleContainer = Object.assign({}, styles.container, this.props.styleContainer);

    return <AxlModal onClose={this.props.closeModal} style={styleContainer} theme={'default'}>
      <E.Title>{cloneElement(this.props.titleFormat)}</E.Title>
      <E.Text>{cloneElement(this.props.textFormat)}</E.Text>
      {this.props.children}
      <E.Control>
        { this.props.okText && <AxlButton compact bg={`red2`} style={{...styles.buttonControlThird, minWidth: 150}} onClick={this.props.onOk}>{this.props.okText}</AxlButton> }
        { this.props.cancelText && <AxlButton compact bg={`black`} style={styles.buttonControlThird} onClick={this.props.onCancel}>{this.props.cancelText}</AxlButton> }
        { this.props.okComponent && cloneElement(this.props.okComponent, {onClick: this.props.onOk}) }
        { this.props.cancleComponent && cloneElement(this.props.cancleComponent, {onClick: this.props.onCancel}) }
      </E.Control>
    </AxlModal>
  }
}

class CustomTheme extends React.Component {
  render() {
    const styleContainer = Object.assign({}, styles.container, this.props.styleContainer);

    return <AxlModal onClose={this.props.closeModal} style={styleContainer} theme={'default'}>
      {this.props.children}
      {(this.props.controls && this.props.controls.length) && <E.Control>{this.props.controls.map(control => {
        if(control.props['close-button']) {
          return cloneElement(control, {onClick: this.props.closeModal});
        } else {
          return cloneElement(control);
        }
      })}</E.Control>}
    </AxlModal>
  }
}