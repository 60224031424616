import React, { Component } from 'react';
import styles, { Container, Checkbox, Label, Checkmark, UnCheckmark } from './styles';
import { ThemeProvider } from 'styled-components';

export default class AxlRadio extends Component {
  render() {
    const props = Object.assign({}, this.props);
    if (props['children']) delete props['children'];
    if (props['theme']) delete props['theme'];
    if (props['color']) delete props['color'];
    if (props['style']) delete props['style'];
    if (props['markColor']) delete props['markColor'];
    if (props['error']) delete props['error'];
    if(this.props.disable) {
      if (props['checked']) delete props['checked'];
      if (props['onChange']) delete props['onChange'];
    }

    return <ThemeProvider theme={{
      type: this.props.theme ? this.props.theme : 'default',
      color: this.props.color ? this.props.color : 'green'
    }}>
      <Container>
        <Label>
          <Checkbox {...props} type={`radio`} />
          {!this.props.disable ? <Checkmark style={this.props.style} markColor={this.props.markColor} isError={!!this.props.error}/> : <UnCheckmark style={this.props.style} />}
          {this.props.children}
        </Label>
      </Container>
    </ThemeProvider>
  }
}
