import styled from 'styled-components';

const ContainerStyle = {
    default: `
        display: flex;
        height: calc(100vh - 100px);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px 15px;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 99999;
    `,
    primary: ``,
    secondary: `
        display: flex;
        height: calc(100vh - 100px);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px 15px;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 99999;
    `
}

const CloseStyle = {
    default: `
        display: flex;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        right: -10px;
        top: -10px;
        z-index: 99999;
        color: #000;
        justify-content: center;
        align-items: center;
        border: 2px solid #000;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
    `,
    primary: ``,
    secondary: `
        display: flex;
        border-radius: 50%;
        position: absolute;
        right: -10px;
        top: -10px;
        z-index: 99999;
        color: #000;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: 0 1px 2px 0 #00000080;
        border: solid 2px #979797;
        background-color: #ffffff;
        width: 35px;
        height: 35px;
        -webkit-tap-highlight-color: transparent;
    `
}

const InnerStyle = {
    default: `
        position: relative;
        z-index: 999;
    `,
    primary: ``,
    secondary: `
        position: relative;
        z-index: 999;
    `
}

const ModalContentStyle = {
    default: `
        max-width: calc(100vw - 60px);
        max-height: calc(100vh - 100px);
        background-color: #fff;
        box-shadow: 0px 0px 3px #aaa;
        overflow: auto;
    `,
    primary: ``,
    secondary: `
        max-width: calc(100vw - 60px);
        max-height: calc(100vh - 100px);
        overflow: auto;
        box-shadow: 1.5px 1px 6.5px 0.5px #00000059;
        border: solid 0.5px #979797;
        background-color: #ffffff;
    `
}

const ModalBgStyle = {
    default: `
        z-index: 998;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #00000033;
    `,
    primary: ``,
    secondary: `
        z-index: 998;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #00000033;
    `
}

export const Container = styled.div`${props => props.theme.type ? ContainerStyle[props.theme.type] : ContainerStyle.default}`;
export const Close = styled.div`${props => props.theme.type ? CloseStyle[props.theme.type] : CloseStyle.default}`;
export const Inner = styled.div`${props => props.theme.type ? InnerStyle[props.theme.type] : InnerStyle.default}`;
export const ModalContent = styled.div`${props => props.theme.type ? ModalContentStyle[props.theme.type] : ModalContentStyle.default}`;
export const ModalBg = styled.div`${props => props.theme.type ? ModalBgStyle[props.theme.type] : ModalBgStyle.default}`;
