import React from 'react';
import * as E from './styles';

export default class AxlTabSimple extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idSelected: props.activedTab || 0
    }
  }

  onSelect = (id) => {
    this.setState({idSelected: id});
  }

  render() {
    const {
      items,
      titleStyle        = {},
      disableRipple     = false,
      align             = 'center',
    }                   = this.props;
    const {idSelected}  = this.state;
    const headerProps = {
      disableRipple
    };
    const headerInnerProps = {
      align,
      scroll: items.length > 2
    }

    return <E.Container>
      <E.Header {...headerProps}>
        <E.HeaderInner {...headerInnerProps}>
          {items.map((item, i) => <E.Title key={i}
                                           style={titleStyle}
                                           active={i === idSelected}
                                           onClick={() => this.onSelect(i)}>{item.title}</E.Title>)}
        </E.HeaderInner>
      </E.Header>
      <E.Main>
        {items.map((item, i) => (i === idSelected) ? <E.Body key={i}>{item.component}</E.Body> : null)}
      </E.Main>
    </E.Container>
  }
}