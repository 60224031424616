import React, { Component } from 'react';

// Utils


// Components

// Styles
import styles from './styles';


class AxlMiniGroupAssignments extends Component {
    constructor(props) {
      super(props)
      this.state = {
        show: true,
        indicator: true
      }
    }

    componentDidMount() {
      // console.log(this.props.indicator)
      if(this.props.indicator !== undefined) {
        this.setState({indicator: this.props.indicator})
      }
    }

    render() {
      return <div style={styles.container}>
        <div style={styles.sectionHeader} onClick={() => this.setState({show: !this.state.show})}>
          <div style={styles.sectionName}>
            {this.state.indicator && <span style={{...styles.statusIndicator, ...{backgroundColor: this.props.color}}}></span>} {this.props.text} ({this.props.length})</div>
          <div style={styles.sectionHeaderLine} />
          <div style={styles.arrow}>
              <span style={!this.state.show ? styles.arrowDown : styles.arrowUp}></span>
          </div>
        </div>
        {this.state.show ? this.props.children : null}
      </div>
    }
}

export default AxlMiniGroupAssignments
