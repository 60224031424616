import React, { Component } from 'react';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { ThemeProvider } from 'styled-components';
import _ from 'lodash';

// Styles
import 'flatpickr/dist/themes/material_green.css'
import { Container, Input, Icon, AxlFlatpickr, Text } from './styles';

export default class AlxFlatDateInput extends Component {
  constructor(props) {
    super(props);
  }

  render () {
    return <Flatpickr {...this.props} />
  }
}
