import styled from 'styled-components';
import Colors from '../../constants/Colors'

export const Container = styled.div`
  box-shadow: 0 2px 10px 0 #00000033;
  background-color: #ffffff;
  position: fixed;
  right: 0;
  top: 0;
  height: calc(100vh - 50px);
  width: 428px;
  z-index: 999;
  padding: 25px 30px;
`;

export const Inner = styled.div``;
