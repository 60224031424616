import styled from 'styled-components';
import { Colors } from '../../constants/Colors';

const ContainerStyle = {
  default: `
    display: inline-block;
  `,
  main: `
    display: inline-block;
    margin: 0 10px;
  `,
  secondary: `
    display: inline-block;
    margin: 0 10px;
  `,
  pink: `
    display: inline-block;
  `,
  stick: `
    display: inline-block;
  `
};

const LabelStyle = {
  default: `
    display: inline-block;
  `,
  main: `
    cursor: pointer;
    font-family: 'AvenirNext';
    font-size: 12px;
    color: #8d8d8d;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  `,
  secondary: `
    cursor: pointer;
    font-family: 'AvenirNext';
    font-size: 12px;
    color: #848484;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  pink: `
    cursor: pointer;
    font-family: 'AvenirNext';
    font-size: 12px;
    color: #848484;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  stick: `
    cursor: pointer;
    font-family: 'AvenirNext';
    font-size: 12px;
    color: #848484;
    display: flex;
    justify-content: center;
    align-items: center;
  `
};

const CheckmarkStyle = {
  default: `
    display: none;
  `,
  main: `
    display: inline-block;
    width: 12px;
    min-width: 12px;
    height: 12px;
    position: relative;
    margin: 2px 5px 0 0;
    border-radius: 3px;
    background-color: #FFF;
    border: 1px solid #aeaeae;
    &:after {
      content: "";
      display: none;
      width: 7px;
      height: 3px;
      position: absolute;
      top: -3px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border-top: 0.5px solid #aeaeae;
      border-right: 0.5px solid #aeaeae;
      border-bottom: 0.5px solid transparent;
      border-left: 0.5px solid transparent;
      transform: rotate(135deg);
    }
  `,
  secondary: `
    display: inline-block;
    width: 14px;
    height: 14px;
    position: relative;
    background: #cfcfcf;
    margin: 0 10px 0 0;
    border-radius: 2px;
    border: 1px solid transparent;
    &:after {
      content: "";
      display: none;
      width: 7px;
      height: 3px;
      position: absolute;
      top: -3px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border-top: 1px solid #FFF;
      border-right: 1px solid #FFF;
      border-bottom: 1px solid transparent;
      border-left: 1px solid transparent;
      transform: rotate(135deg);
    }
  `,
  pink: `
    display: inline-block;
    border-radius: 3px;
    border: solid 1px #e4e4e4;
    background-color: #f9f9f9;
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
  `,
  stick: `
    display: inline-block;
    width: 18px;
    height: 18px;
    position: relative;
    background: #cfcfcf;
    margin: 0 10px 0 0;
    border-radius: 3px;
    border: 1px solid #e4e4e4;
    &:after {
      content: "";
      display: none;
      width: 7px;
      height: 3px;
      position: absolute;
      top: -3px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border-top: 2px solid #FFF;
      border-right: 2px solid #FFF;
      border-bottom: 2px solid transparent;
      border-left: 2px solid transparent;
      transform: rotate(135deg);
    }
  `
};

const CheckboxStyle = {
  default: ``,
  main: `
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
    &:checked ~ span {
      &:after {
        display: inline-block;
      }
    }
  `,
  secondary: `
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
    &:checked ~ span {
      &:after {
        display: inline-block;
      }
    }
  `,
  pink: `
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
    margin: 0;
    &:checked ~ span {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: solid 4px #9a93f9;
      background-color: #f9f9f9;
    }
  `,
  stick: `
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
    &:checked ~ span {
      border-color: #887fff;
      background: #887fff;
      &:after {
        display: inline-block;
      }
    }
  `
};

const CheckboxBgStyle = {
  transparent: `background-color: transparent`,
  default: `background-color: ${Colors.darkPastelGreen}`,
  green: `background-color: ${Colors.darkPastelGreen}`,
  blue: `background-color: ${Colors.lightBlue}`,
  bluenight: `background-color: #273c75`,
  periwinkle: `background-color: ${Colors.periwinkle}`,
  white: `background-color: #FFF`,
  darkPastelGreen: `background-color: ${Colors.darkPastelGreen}`,
  veryLightPink: `background-color: ${Colors.veryLightPink}`,
  grayLight: `background-color: #f9f9f9`,
}

const UnCheckmarkStyle = {
  default: `
    display: inline-block;
    width: 12px;
    min-width: 12px;
    height: 12px;
    position: relative;
    margin: 2px 5px 0 0;
    border-radius: 0;
    background-color: #FFF;
    border-bottom: 1px solid #aeaeae;
    background-color: #FFF;
  `,
  main: `
    display: inline-block;
    width: 12px;
    min-width: 12px;
    height: 12px;
    position: relative;
    margin: 2px 5px 0 0;
    border-radius: 0;
    background-color: #FFF;
    border-bottom: 1px solid #aeaeae;
    background-color: #FFF;
  `,
  secondary: `
    display: inline-block;
    width: 12px;
    min-width: 12px;
    height: 12px;
    position: relative;
    margin: 2px 5px 0 0;
    border-radius: 0;
    background-color: #FFF;
    border-bottom: 1px solid #aeaeae;
    background-color: #FFF;
  `,
  pink: `
    display: inline-block;
    width: 12px;
    min-width: 12px;
    height: 12px;
    position: relative;
    margin: 2px 5px 0 0;
    border-radius: 0;
    background-color: #FFF;
    border-bottom: 1px solid #aeaeae;
    background-color: #FFF;
  `,
  stick: `
    display: inline-block;
    width: 12px;
    min-width: 12px;
    height: 12px;
    position: relative;
    margin: 2px 5px 0 0;
    border-radius: 0;
    background-color: #FFF;
    border-bottom: 1px solid #aeaeae;
    background-color: #FFF;
  `
}

export const Container = styled.div`${props => props.theme.type ? ContainerStyle[props.theme.type] : ContainerStyle.default}`;

export const Label = styled.label`${props => props.theme.type ? LabelStyle[props.theme.type] : LabelStyle.default}`;

export const Checkmark = styled.span`
  ${props => props.theme.type ? CheckmarkStyle[props.theme.type] : CheckmarkStyle.default}
  ${props => props.theme.color ? CheckboxBgStyle[props.theme.color] : CheckboxBgStyle.default}
  &:after {
    border-top-color: ${props => props.markColor ? props.markColor : '#000'};
    border-right-color: ${props => props.markColor ? props.markColor : '#000'};
  }
`;

export const UnCheckmark = styled.span`${props => props.theme.type ? UnCheckmarkStyle[props.theme.type] : UnCheckmarkStyle.default}`;
export const Checkbox = styled.input`${props => props.theme.type ? CheckboxStyle[props.theme.type] : CheckboxStyle.default}`;
