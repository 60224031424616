import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles, { Container, TextInput, Error, ErrorIcon, InputWrap } from './styles';
import { ThemeProvider } from 'styled-components';

class AxlInput extends Component {
  constructor(props) {
    super(props)
    this.onKeyUp = this.onKeyUp.bind(this)
  }
  noop() {}

  onKeyUp(event) {
    this.props.onEnter && event.key === 'Enter' && this.props.onEnter()
    this.props.onKeyPress && this.onKeyPress(event)
  }

  render() {
    const disabledStyle = this.props.disabled ? {
      opacity: 0.6,
      cursor: 'not-allowed'
    } : {};

    return <ThemeProvider theme={{type: this.props.theme ? this.props.theme : 'default'}}>
      <Container>
        {this.props.error && this.props.errorTop && <Error>{this.props.error}</Error>}
        <InputWrap>
          <TextInput
            min={this.props.min}
            max={this.props.max}
            errorTop={this.props.errorTop}
            errorIcon={this.props.errorIcon}
            isError={!!this.props.error}
            placeholder={this.props.placeholder}
            onChange={ this.props.onChange ? this.props.onChange : this.noop }
            onKeyUp={ this.onKeyUp }
            defaultValue={ this.props.defaultValue }
            value={ this.props.value }
            name={this.props.name ? this.props.name: undefined}
            type={ this.props.type }
            disabled={this.props.disabled}
            style={{...{width: this.props.fluid ? '100%' : 'auto'},...styles.container, ...this.props.style, ...disabledStyle}} />
          {this.props.errorIcon && <ErrorIcon className="fa fa-times-circle-o" />}
        </InputWrap>
        {this.props.error && this.props.showError && !this.props.errorTop && <Error>{this.props.error}</Error>}
      </Container>
    </ThemeProvider>
  }
}

AxlInput.propTypes = {
  showError: PropTypes.bool
}

AxlInput.defaultProps = {
 showError: true
}

export default AxlInput
