import styled from 'styled-components';
import { buttons } from '../../themes/';
import { Colors } from '../../constants/Colors';

export const Container = styled.div`
  display: inline-block;
`;

export const Button = styled.button`
  position: relative;
`;

export const Icon = styled.i`
  width: 14px;
  height: 14px;
  position: absolute;
  left: 14px;
  top: 0;
  bottom: 0;
  margin: auto;
  line-height: 14px;
`;

export const Image = styled.img`
  max-width: 17px;
  max-height: 17px;
  position: absolute;
  left: 12px;
  top: 0;
  bottom: 0;
  margin: auto;
`;

export default {
    container: {
      display: 'inline-block'
    },
    styles: {
      default: {
        marginTop: '15px',
        marginBottom: '15px',
        paddingLeft: '15px',
        paddingRight: '15px',
      },
      compact: {
        marginTop: '4px',
        marginBottom: '4px',
        paddingLeft: '5px',
        paddingRight: '5px',
      },
      tiny: {
        marginTop: '0px',
        marginBottom: '0px',
        paddingLeft: '0px',
        paddingRight: '0px',
      }
    },
    shadow: {
      none: {
        boxShadow: 'none'
      },
      default: {
        boxShadow: '1px 3px 3px #8a8a8a'
      }
    },
    button: {
      default: {
        ...buttons.default,
        ':hover': {
          backgroundColor: '#70B920'
        }
      },
      defaultWithIcon: {
        paddingLeft: '30px'
      },
      pink: {
        ...buttons.default,
        backgroundColor: '#887fff',
        ':hover': {
          backgroundColor: '#7A71EE'
        }
      },
      periwinkle: {
        ...buttons.default,
        backgroundColor: '#887fff',
        ':hover': {
          backgroundColor: '#7A71EE'
        }
      },
      borPinkBgWhite: {
        ...buttons.default,
        backgroundColor: '#FFF',
        border: '1px solid #887fff',
        color: '#887fff'
      },
      greyOne: {
        cursor: 'pointer',
        borderRadius: '2px',
        border: `solid 0.8px ${Colors.pinkishGrey}`,
        backgroundColor: '#fafafa',
        verticalAlign: 'middle',
        padding: '0 10px',
        fontFamily: 'AvenirNext-DemiBold',
        fontWeight: 'bold',
        color: '#aeaeae',
        ':hover': {
          borderRadius: '2px',
          border: `solid 0.8px ${Colors.pinkishGrey}`,
          backgroundColor: '#fafafa'
        }
      },
      blueGray: {
        ...buttons.default,
        backgroundColor: Colors.blueyGrey
      },
      blueNight: {
        ...buttons.default,
        backgroundColor: '#2a2444',
        ':hover': {
          backgroundColor: '#443b69'
        }
      },
      blueGrayMain: {
        ...buttons.default,
        backgroundColor: '#7a8396',
        ':hover': {
          backgroundColor: '#7a8396'
        }
      },
      borderBlueGrayMain: {
        ...buttons.default,
        backgroundColor: 'transparent',
        border: '1px solid #7a8396',
        fontFamily: 'AvenirNext-DemiBold',
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: '0.4px',
        color: '#7a8396',
        padding: '8px 20px',
        ':hover': {
          backgroundColor: 'transparent',
          border: '1px solid #7a8396',
        }
      },
      red: {
        ...buttons.default,
        backgroundColor: '#fa6725'
      },
      red2: {
        ...buttons.default,
        backgroundColor: '#e0112a'
      },
      bluish: {
        ...buttons.default,
        backgroundColor: '#297ec8',
        color: '#FFF',
        border: '1px solid #297ec8'
      },
      borBluish: {
        ...buttons.default,
        backgroundColor: '#ffffff',
        color: '#9b9b9b',
        border: '1px solid #297fc9'
      },
      white: {
        ...buttons.default,
        backgroundColor: '#fff',
        color: '#aaa',
        border: '1px solid #e4e4e4'
      },
      none: {
        ...buttons.default,
        background: 'none',
        color: '#aaa',
        border: '1px solid #bebfc0'
      },
      gray: {
        ...buttons.default,
        background: '#f4f4f4',
        color: '#aaa',
        border: '1px solid #e4e4e4',
        ':hover': {}
      },
      black: {
        ...buttons.default,
        background: '#fff',
        color: '#4a4a4a',
        border: '1px solid #4a4a4a',
        ':hover': {
          background: '#fff',
          border: '1px solid #8e8e8e',
        }
      },
      maize: {
        ...buttons.default,
        background: Colors.maize,
        color: '#fff'
      },
      whiteBorLightGrey: {
        ...buttons.default,
        background: '#FFF',
        color: Colors.lightGrey,
        border: '1px solid #96979a'
      },
      disabled: {
        ...buttons.default,
        backgroundColor: '#e4e4e4',
        cursor: 'default',
        verticalAlign: 'middle'
      },
      compact: {
        height: '32px',
        fontSize: '13px'
      },
      tiny: {
        height: '28px',
        fontSize: '11px',
        padding: '5px 10px'
      },
      icon: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        width: 'auto',
        height: 'auto',
        padding: 0,
        margin: 0,
        outline: 'none',
        cursor: 'pointer'
      },
      iconBgWhiteGreenBor: {
        backgroundColor: '#FFF',
        border: '1px solid #76c520',
        padding: '18px 6px 18px 30px',
        margin: 0,
        outline: 'none',
        cursor: 'pointer',
        borderRadius: '50%',
        boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.2)',
        ':hover': {
          border: '1px solid #61a01d'
        }
      },
      greenNoBor: {
        borderRadius: '3px',
        border: 'solid 2px #76c520',
        backgroundColor: '#ffffff',
        color: '#2d414e',
        fontSize: '14px',
        cursor: 'pointer'
      },
      blueLight: {
        ...buttons.default,
        color: '#0068ff',
        border: '1px solid #0068ff',
        background: 'transparent'
      },
      greenLight: {
        ...buttons.default,
        color: '#009c4f',
        border: '1px solid #009c4f',
        background: 'transparent'
      },
      blueNight2: {
        ...buttons.default,
        color: '#009c4f',
        background: '#12054c'
      },
      blueNight3: {
        ...buttons.default,
        background: '#FFF',
        border: 'solid 1px #5e5b78',
        fontFamily: 'AvenirNext-Bold',
        fontSize: '18px',
        lineHeight: '12px',
        letterSpacing: '0.75px',
        color: '#5e5b78'
      },
      whiteBorderGray_1: {
        ...buttons.default,
        background: '#FFF',
        border: '1px solid #9b9b9b',
        color: '#989798',
        fontSize: 14,
        fontWeight: 'bold'
      }
    }
}
