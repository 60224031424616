import React, { Component } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Dropzone from 'react-dropzone';
import AxlButton from '../AxlButton';

// Utils
import { dataURLtoFile } from '../../Utils/imageConverter';

import { Container, CropImage, DropContainer, DropTitle, Title, Inner, Bottom, DropInner, ErrorText } from './styles';

export default class AxlUploadCropImage extends Component {
  constructor(props){
    super(props);
    this.state = {
      files: [],
      canvasDataUrl: null,
      isReject: false
    }
    this.cropRef = React.createRef();
    this._crop = this._crop.bind(this);
  }

  _crop() {
    const canvas = this.cropRef.current.cropper.getCroppedCanvas().toDataURL();
    this.setState({canvasDataUrl: canvas});
  }

  handleFileDrops() {}

  addImage = (e) => {
    const {onOK} = this.props;
    const { files, canvasDataUrl } = this.state;
    if(canvasDataUrl) {
      const file = dataURLtoFile(canvasDataUrl, 'test.png')
      onOK([file]);
    }
  };

  onDrop = (acceptFiles) => {
    const files = acceptFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }));
    this.setState({files: files});
  };

  onDropRejected = () => {
    this.setState({
      isReject: true
    });
  }

  onDropAccepted = () =>
    this.setState({
      isReject: false
    });

  render() {
    const { canvasDataUrl } = this.state;
    const {onCancel, onOK, adding} = this.props;

    return (<Container>
      <Title>{`Upload image`}</Title>
      <Inner>
        <Dropzone accept={'.png'} multiple={false} onDrop={this.onDrop} onDropRejected={this.onDropRejected} onDropAccepted={this.onDropAccepted}>
          {({getRootProps, getInputProps}) => (
            <DropContainer>
              <DropInner {...getRootProps()}>
                <input {...getInputProps()} />
                <DropTitle>{this.props.title ? this.props.title : `Drag 'n' drop some files here, or click to select files`}</DropTitle>
              </DropInner>
            </DropContainer>
          )}
        </Dropzone>
        {this.state.files.length > 0 &&
          <CropImage
            ref={this.cropRef}
            src={this.state.files[0].preview}
            aspectRatio={1 / 1}
            guides={false}
            crop={this._crop}
            minCropBoxWidth={160}
            minCropBoxHeight={160} />
        }
        {this.state.isReject && <ErrorText>{`Invalid File Extension(.png)`}</ErrorText>}
      </Inner>
      <Bottom>
        <AxlButton onClick={onCancel} compact bg={`gray`}>{`Cancel`}</AxlButton>
        <AxlButton loading={adding !== undefined ? adding : false} onClick={this.addImage} compact bg={`blueGray`} disabled={this.state.isReject}>{`Add Image`}</AxlButton>
      </Bottom>
    </Container>);
  }
}
