import React from 'react';
import * as E from './styles';

export default class AxlCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowPanel: false,
    };
  }

  render() {
    const {header, children} = this.props;
    const {isShowPanel} = this.state;

    return (
      <E.Container>
        <E.Header>
          <E.Title>
            {header}
          </E.Title>
          <E.Arrow onClick={() => this.setState({isShowPanel: !isShowPanel})}>
            <E.I className={!isShowPanel ? 'fa fa-angle-down' : 'fa fa-angle-up'} />
          </E.Arrow>
        </E.Header>
        {isShowPanel && <E.Inner>{children}</E.Inner>}
      </E.Container>
    );
  }
}