import { action, makeObservable } from 'mobx';

class AppealStore {
  constructor(api) {
    makeObservable(this, {
      getList: action,
      viewFile: action,
      addComment: action,
      listComment: action,
      update: action,
      getCategory: action
    });

    this.api = api;
  }

  getList = (query) => {
    if(!query) return;

    return this.api.post(`/appeals/search`, query);
  };

  viewFile = (id) => {
    if(!id) return;

    return this.api.get(`/appeals/${id}/files`);
  };

  addComment = (query) => {
    if(!query) return;

    return this.api.post(`appeals/comments`, query);
  };

  listComment = (id = null, limit = 10, offset = 0) => {
    if(!id) return;

    return this.api.get(`/appeals/${id}/comments/limit/${limit}/offset/${offset}`);
  };

  update = (id, status, remark = '') => {
    if(!id || !status) return;

    return this.api.put(`/appeals/${id}/status/${status}/remark/${remark}`);
  };

  getCategory = () => {
    return this.api.get(`/appeals/categories`);
  };
}

export default AppealStore;
