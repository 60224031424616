import React, { Component } from 'react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import Select, { components } from 'react-select';
import _ from 'lodash';
import PropTypes from 'prop-types';

// Components
import AxlButton from '../AxlButton';

// Styles
import styles, * as E from './styles';

export default class AxlMultiSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.defaulValue
    }
    this.buttonLabel = this.buttonLabel.bind(this)
  }

  componentWillReceiveProps(props) {
    // check for value ...
    if (this.state.value && props.options !== this.props.options) {
      if (Array.isArray(this.state.value)) {
        this.state.value.forEach(v => {
          let opt = props.options.filter(o => o.value === v.value)
          if (opt && opt.length > 0) v.label = opt[0].label
        })
      } else {
        let opt = props.options.filter(o => o.value === this.state.value.value)
        if (opt && opt.length > 0)
          this.state.value.label = opt[0].label
      }
    }
  }

  handleOnChange = (value) => {
    if (this.props.singular) {
      if (!value || value.length < 1) {
        if (this.props.allowAll) {
          this.setState({value: value})
        } else
          return; // do not allow to change
      }
      else if (value.length > 1) {
        // check for new
        let old = this.state.value ? this.state.value.map(v => v.value) : []
        let newlySelected = value.filter(v => old.indexOf(v.value) < 0)
        this.setState({value: newlySelected})
        this.props.onChange && this.props.onChange(newlySelected)
        return
      }
      this.setState({value: value})
    } else {
      this.setState({value: value})
    }
    this.props.onChange && this.props.onChange(value)
  }

  buttonLabel(vals) {
    var placeholderButtonLabel = vals.placeholderButtonLabel,
        value = vals.value;

    if (!value) {
      return placeholderButtonLabel;
    }

    if (Array.isArray(value)) {
      if (value.length === 0) {
        return placeholderButtonLabel;
      }

      if (this.props.showValues)
        return value.map(v => v.label).join(', ')

      if (value.length === 1) {
        return value[0].label;
      }

      return "".concat(value.length, " selected");
    }

    return value.label;
  }

  clearValue = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({value: []})
    this.props.onChange && this.props.onChange([])
  }

  render() {
    const isClear = _.indexOf(['primary'], this.props.theme) === -1;
    const menuList = this.props.customStyle && this.props.customStyle.menuList || [];
    const customStyles = {
      container: () => styles[this.props.theme].container,
      dropdownButton: () => styles[this.props.theme].dropdownButton,
      option: () => styles[this.props.theme].option,
      control: () => styles[this.props.theme].control,
      singleValue: () => styles[this.props.theme].singleValue,
      valueContainer: () => styles[this.props.theme].valueContainer,
      menuList: () => [styles[this.props.theme].menuList, menuList],
      input: () => styles[this.props.theme].input,
      indicatorsContainer: () => styles[this.props.theme].indicatorsContainer,
      indicatorSeparator: () => styles[this.props.theme].indicatorSeparator,
      dropdownIndicator: () => styles[this.props.theme].dropdownIndicator,
      placeholder: () => styles[this.props.theme].placeholder
    }


    const Option = ({ children, ...props }) => (
      <components.Option {...props}>
        <E.Label><E.Checkbox className={props.isSelected ? 'checked' : ''} />{children}</E.Label>
      </components.Option>
    );

    const IndicatorsContainer = props => {
      return (
        <E.IndicatorsContainer>
          <components.IndicatorsContainer {...props} />
        </E.IndicatorsContainer>
      );
    };

    const DropdownButtonIcon = () => {
      return <E.DropdownIconContainer>
        {!isClear && <E.ClearSearch onClick={this.clearValue}><i className="fa fa-times" /></E.ClearSearch>}
        <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation">
          <path d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z" fill="currentColor" fillRule="evenodd"></path>
        </svg>
      </E.DropdownIconContainer>
    };

    const ClearIndicator = props => {
      const {
        children = <E.ClearSearch onClick={this.clearValue}><i className="fa fa-times" /></E.ClearSearch>,
        getStyles,
        innerProps: { ref, ...restInnerProps },
      } = props;
      return (
        <E.ClearIndicator
          {...restInnerProps}
          ref={ref}
        >{children}</E.ClearIndicator>
      );
    };

    const selectComponents = {
      Option, ClearIndicator, DropdownButtonIcon, IndicatorsContainer,

    };

    return <E.Container style={this.props.style}>
      <ReactMultiSelectCheckboxes
        isClearable={this.props.isClearable}
        isSearchable={this.props.isSearchable}
        components={selectComponents}
        styles={customStyles}
        getDropdownButtonLabel={this.buttonLabel}
        value={this.state.value}
        onChange={value => this.handleOnChange(value)}
        placeholder={this.props.placeholder}
        placeholderButtonLabel={this.props.placeholderButtonLabel}
        options={this.props.options}
        hideSearch={!this.props.isSearchable}
      />
      { isClear && <E.ClearIcon onClick={this.clearValue} style={styles.clearIcon}><i className="fa fa-times" /></E.ClearIcon> }
    </E.Container>
  }
}

AxlMultiSelect.defaultProps = {
  theme: 'default',
  isClearable: false,
  isSearchable: true
}

AxlMultiSelect.propTypes = {
  theme: PropTypes.string,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool
}
