import styled from 'styled-components';
import Flatpickr from 'react-flatpickr';

const AxlFlatpickrStyle = {
  default: `
    display: block;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
  `,
  main: `
    display: block;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
  `,
  secondary: `
    display: block;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
  `
}

const ContainerStyle = {
  default: `
    display: flex;
    margin: 0 10px;
    position: relative;
  `,
  main: `
    display: flex;
    position: relative;
  `,
  secondary: `
    display: flex;
    position: relative;
  `
}

const InputStyle = {
  default: `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 175px;
    border-bottom: 1px solid #887fff;
  `,
  main: `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 38px;
    padding: 10px 15px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 3px;
    color: #3b3b3b;
    font-size: 17px;
    flex: 1;
  `,
  secondary: `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 26px;
    padding: 5px 15px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 3px;
    color: #3b3b3b;
    font-size: 17px;
    flex: 1;
    background-color: #FFF;
  `
}

const IconStyle = {
  default: `
    width: 0px;
    height: 0px;
    display: inline-block;
    border-top: 5px solid #887fff;
    border-left: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid transparent;
  `,
  main: `
    width: 0px;
    height: 0px;
    display: inline-block;
    border-top: 5px solid #bebfc0;
    border-left: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid transparent;
  `,
  secondary: `
    width: 0px;
    height: 0px;
    display: inline-block;
    border-top: 5px solid #bebfc0;
    border-left: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid transparent;
  `
};

const TextStyle = {
  default: `
    font-family: 'AvenirNext-Bold';
    font-size: 18px;
    font-weight: bold;
    color: #887fff;
    display: flex;
    flex: 1;
  `,
  main: `
    font-family: AvenirNext;
    font-size: 14px;
    color: #000;
    display: flex;
    flex: 1;
  `,
  secondary: `
    font-family: AvenirNext;
    font-size: 14px;
    color: #000;
    display: flex;
    flex: 1;
  `
}

const PlaceHoderStyle = {
  default: `
    font-family: AvenirNext;
    font-size: 14px;
    color: #bfbfbf;
    display: flex;
    flex: 1;
  `,
  main: `
    font-family: AvenirNext;
    font-size: 14px;
    color: #bfbfbf;
    display: flex;
    flex: 1;
  `,
  secondary: `
    font-family: AvenirNext;
    font-size: 14px;
    color: #bfbfbf;
    display: flex;
    flex: 1;
  `
}

const SelectContainerStyle = {
  default: ``,
  main: `
    margin: 0 3px;
  `,
  secondary: `
    margin: 0 3px;
  `
}

const ButtonContainerStyle = {
  default: ``,
  main: `
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 3px;
  `,
  secondary: `
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 3px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  `
}

const ErrorStyle = {
  default: `
    color: red;
    margin: 5px 0 0;
    font-size: 11px;
  `,
  main: `
    color: red;
    margin: 5px 0 0;
    font-size: 11px;
  `,
  secondary: `
    color: red;
    margin: 5px 0 0;
    font-size: 11px;
  `
};
const WarningStyle = {
  default: `
    color: #ffc107;
    margin: 5px 0 0;
    font-size: 11px;
  `,
  main: `
    color: #ffc107;
    margin: 5px 0 0;
    font-size: 11px;
  `,
  secondary: `
    color: #ffc107;
    margin: 5px 0 0;
    font-size: 11px;
  `
};

const TextInputErrorStyle = {
  default: `
    border: solid 1px #d0021b;
    outline: none;
  `,
  main: `
    border: solid 1px #d0021b;
    outline: none;
  `,
  secondary: `
    border: solid 1px #d0021b;
    outline: none;
  `
};

const TextInputWarningStyle = {
  default: `
    border: solid 1px #ffc107;
    outline: none;
  `,
  main: `
    border: solid 1px #ffc107;
    outline: none;
  `,
  secondary: `
    border: solid 1px #ffc107;
    outline: none;
  `
};

export const AxlFlatpickr = styled(Flatpickr)`${props => props.theme.type ? AxlFlatpickrStyle[props.theme.type] : AxlFlatpickrStyle.default}`;
export const Container = styled.div`${props => props.theme.type ? ContainerStyle[props.theme.type] : ContainerStyle.default}`;
export const Input = styled.div`
  ${props => props.theme.type ? InputStyle[props.theme.type] : InputStyle.default}
  ${props => props.isError ? props.theme.type ? TextInputErrorStyle[props.theme.type] : TextInputErrorStyle.default : {}}
  ${props => props.isWarning ? props.theme.type ? TextInputWarningStyle[props.theme.type] : TextInputWarningStyle.default : {}}
`;
export const Icon = styled.div`${props => props.theme.type ? IconStyle[props.theme.type] : IconStyle.default}`;
export const Text = styled.div`${props => props.theme.type ? TextStyle[props.theme.type] : TextStyle.default}`;
export const PlaceHoder = styled.div`${props => props.theme.type ? PlaceHoderStyle[props.theme.type] : PlaceHoderStyle.default}`;
export const SelectContainer = styled.div`${props => props.theme.type ? SelectContainerStyle[props.theme.type] : SelectContainerStyle.default}`;
export const ButtonContainer = styled.div`${props => props.theme.type ? ButtonContainerStyle[props.theme.type] : ButtonContainerStyle.default}`;
export const Error = styled.p`${props => props.theme.type ? ErrorStyle[props.theme.type] : ErrorStyle.default}`;
export const Warning = styled.p`${props => props.theme.type ? WarningStyle[props.theme.type] : WarningStyle.default}`;
