import { observable, action, makeObservable } from 'mobx';

class DspStore {
  constructor(api) {
    makeObservable(this, {
      dspSearchResult: observable,
      couriers: observable,
      search: action,
      listAll: action
    });

    this.baseUrl = '';
    this.api = api;
  }

  dspSearchResult = [];
  couriers = [];

  search(keyword) {
    this.api.get(`/couriers/advanced-search/${keyword}`)
      .then(action(response => {
        if (response.status === 200) {
          this.dspSearchResult = response.data;
        }
      }));
  }

  listAll(onAssignScreen = false) {
    this.api.get(`/couriers/search?size=100`).then(action(response => {
      if (response.status === 200) {
        this.couriers = response.data;
        if (onAssignScreen) {
          this.dspSearchResult = response.data.couriers;
        }
      }
    }));
  }
}

export default DspStore;
