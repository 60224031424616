import { initializeApp } from 'firebase/app';
import { isSupported, getMessaging, getToken, onMessage, deleteToken } from 'firebase/messaging';
import { firebaseConfig, usePublicVapidKey } from 'firebase-config';

class Firebase {
  constructor() {
    this.app = null;
    this.messaging = null;
    if (!this.app) {
      isSupported().then((supported) => {
        if (supported) {
          this.app = initializeApp(firebaseConfig);
          this.messaging = getMessaging(this.app);
        }
      });
    }

    this.requesting = true;
    this.token = null;
    this.permission = null;
  }

  initFirebaseMessage = async () => {
    try {
      if (this.messaging) {
        this.permissionRequest = Notification.requestPermission().then((result) => {
          if (result === 'granted') {
            getToken(this.messaging, { vapidKey: usePublicVapidKey }).then((token) => (this.token = token));
          }

          this.permission = result;
          this.requesting = false;
        });
      }
    } catch (error) {
      console.log('Error:initFirebaseMessage', error);
    }
  };

  requestPermissionPushNotifications = async () => {
    try {
      await Notification.requestPermission();
      if (this.messaging) {
        if (this.requesting) {
          await this.permissionRequest;
        }

        const token = await getToken(this.messaging, { vapidKey: usePublicVapidKey });
        this.token = token;

        return token;
      }

      return this.token;
    } catch (error) {
      try {
        if (Notification.permission !== 'granted') {
          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              alert('Notification enabled.');
            }
          });
        }
      } catch (e) {
        console.error(e);
      }
      console.error(error);
    }
  };

  deleteToken = () => {
    if (!this.token || !this.messaging) return false;

    return deleteToken(this.messaging);
  };

  onMessage = (cb) => {
    if (this.messaging) {
      onMessage(this.messaging, (payload) => {
        if (cb) cb(payload);

        return payload;
      });
    }
  };
}

export default Firebase;
