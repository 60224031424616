import React, { Component } from 'react';
import {
  Container,
  ContainerInner,
  MenuList,
  MenuItem,
  MenuListParent,
  MenuItemChild
} from './styles';

export default class AxlMainMenu extends Component {
  componentDidMount() {
    var scrollItem = document.getElementsByClassName('horizontal-scroll-actived')[0];
    scrollItem.addEventListener("wheel", function(e) {
      if (e.deltaY > 0) scrollItem.scrollLeft += 100;
      else scrollItem.scrollLeft -= 100;
      e.preventDefault();
    })
  }

  render() {
    const { open, items, current } = this.props;

    return <Container className={open ? 'show' : 'hide'}>
      <ContainerInner>
        <MenuList className={`horizontal-scroll-actived`}
                  style={{justifyContent: this.props.center ? 'center' : 'flex-start'}}>
          { this.props.children ? this.props.children : (
            items.map((item, index) => item.child ? (
              <MenuItem onClick={() => this.props.onSelect && this.props.onSelect(item) } key={index} className={item.value === current ? 'active' : ''}>
                {item.name}
                <MenuListParent>
                  {item.child.map((i, index) => <MenuItemChild onClick={() => this.props.onSelect && this.props.onSelect(i) } key={index} className={i.value === current ? 'active' : ''}>{i.name}</MenuItemChild>)}
                </MenuListParent>
              </MenuItem>
            ) : <MenuItem onClick={() => this.props.onSelect && this.props.onSelect(item) } key={index} className={item.value === current ? 'active' : ''}>{item.name}</MenuItem>)
          )}
        </MenuList>
      </ContainerInner>
    </Container>
  }
}
