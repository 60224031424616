import React, { Component } from 'react';
import styles, { Container, Inner, ContainerTag, Row, Col, Text, Name, SmallText, Logo, RibbonDeleted, DayText, TimeZoneText } from './styles';
// import Moment from 'react-moment';
import moment from 'moment-timezone';
import _ from 'lodash';

// Component
import AxlTags from '../AxlTags';

class AxlMiniStopBox extends Component {
    render() {
        const { stop } = this.props
        const { shipment, label, client, _deleted } = this.props.stop
        const driver_label = label ? label.driver_label : '-'
        const street = shipment ? shipment.dropoff_address.street : '-'
        const customer_name = shipment ? shipment.customer.name : ''
        const city = shipment ? shipment.dropoff_address.city + ', ' + shipment.dropoff_address.state + ' ' + shipment.dropoff_address.zipcode : ''
        const deletedStyle = _deleted || stop.status === 'DISCARDED' ? { borderTop: '1px solid #d8313280', borderBottom: '1px solid #d8313280', borderLeft: '1px solid #d8313280', borderColor: '#d8313280', boxShadow: 'none' } : {}
        let deletedContent = null;
        if (_deleted) {
          deletedContent = "DELETED";
        } else if (stop.status === 'DISCARDED') {
          deletedContent = 'DISCARDED';
        } else if(shipment.inbound_status) {
          switch (shipment.inbound_status) {
            case 'MISSING':
              deletedContent = "MISSING";
              break;
            case 'RECEIVED_DAMAGED':
              deletedContent = "DAMAGED";
              break;
            case 'DUPLICATED':
              deletedContent = "DUPLICATED";
              break;
            default:
              break;
          }
        }

        const localTimezone     = moment.tz.guess();
        const defaultTimezone   = _.has(shipment, 'timezone') && !_.isEmpty(_.has(shipment, 'timezone')) ? _.get(shipment, 'timezone') : 'America/Los_Angeles';
        const status = stop.status === 'PENDING' ? (shipment.status === 'PICKUP_SUCCEEDED' ? 'IN_PROGRESS' : 'PENDING') : stop.status
        const isSameTimezone = moment.tz(defaultTimezone).utcOffset() === moment.tz(localTimezone).utcOffset();
        const isDiffDay = moment.tz(shipment.dropoff_earliest_ts, defaultTimezone).diff(moment.tz(localTimezone), 'days');
        const showDiffDay = isDiffDay !== 0 ? `${isDiffDay > 0 ? ' +' : ''} ${isDiffDay}day${Math.abs(isDiffDay) > 1 ? 's' : ''}` : '';
        const t1  = moment.tz(shipment.dropoff_earliest_ts, localTimezone).format('h:mma'),
              t2  = moment.tz(shipment.dropoff_latest_ts, localTimezone).format('h:mma'),
              tz2 = moment.tz(shipment.dropoff_latest_ts, localTimezone).format('z'),
              t3  = moment.tz(shipment.dropoff_earliest_ts, defaultTimezone).format('h:mma'),
              t4  = moment.tz(shipment.dropoff_latest_ts, defaultTimezone).format('h:mm a'),
              tz4 = moment.tz(shipment.dropoff_latest_ts, defaultTimezone).format('z');

        return <Container
            onClick={ () => this.props.onClick && this.props.onClick()}
            style={{...this.props.style, ...styles.statuses[stop.type][status], ...deletedStyle}}>
            { client && client.logo_url && <Logo><img style={{maxWidth: '100%', maxHeight: '100%'}} src={client.logo_url} /></Logo> }
            { deletedContent && <RibbonDeleted>{deletedContent}</RibbonDeleted>}
            <Inner>
              <Row>
                  <Col style={{flex: 0.6}}>
                    <div>
                      <Text>
                        <span>{ driver_label }&nbsp;</span>
                        <SmallText>
                          [{t1} - {t2} <TimeZoneText>{tz2}</TimeZoneText>]
                          {!isSameTimezone ? <span>[{t3} - {t4} <TimeZoneText>{tz4}</TimeZoneText>]</span> : ''}
                          <DayText>{showDiffDay}</DayText>
                        </SmallText>
                      </Text>
                    </div>
                    <div>
                      <Name>{ customer_name }</Name>
                    </div>
                  </Col>
                <Col style={{flex: 0.4}}>
                    <div>
                      <Text className={'text-right'}>{ street }</Text>
                    </div>
                    <div>
                      <Text className={'text-right'}>{ city }</Text>
                    </div>
                  </Col>
              </Row>
            </Inner>
            {(this.props.tags && this.props.tags.length > 0) && <ContainerTag>
              <AxlTags isShowLabel={this.props.isShowLabelTag} tags={this.props.tags} />
            </ContainerTag>}
        </Container>
    }
}

export default AxlMiniStopBox
