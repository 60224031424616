import styled from 'styled-components';
import Cropper from 'react-cropper';

export const Container = styled.div`
  padding: 10px 0 0;
`;

export const Title = styled.div`
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #e5e5e5;
`;

export const Inner = styled.div`
  padding: 30px 15px;
`;

export const Bottom = styled.div`
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
`;

export const CropImage = styled(Cropper)`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
`;

export const DropContainer = styled.div`
  background: #F5F5F5;
  color: #000000;
  border: 2px dashed #c5c4c4;
  border-radius: 3px;
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  transition: 0.2s;
`;

export const DropInner = styled.div`
  padding: 30px 20px;
  outline: none;
  cursor: pointer;
`;

export const DropTitle = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
`;
export const ErrorText = styled.div`
  font-size: 11px;
  color: red;
  margin-top: 10px;
`;