import _ from 'lodash';
import { observable, action, makeObservable } from 'mobx';

class SensitiveInfoStore {
  constructor(api) {
    makeObservable(this, {
      data: observable,
    });
    this.api = api;
  }

  data = {};

  /**
   * @param {*} uid: DR_001, US_001 ...
   */
  getSensitiveInfo = (uid, field, reason, reasonCode, extraParams) => {
    const fields = [field]
    const params = { 'extra_params': extraParams,
                    fields, 
                    reason,
                    source: "dispatcher_app"
                  }
    return this.api.post(`/sensitive-info/${uid}/reveal`, params);
  }
}

export default SensitiveInfoStore;
