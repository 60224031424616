export default {
    container: {
        backgroundColor: 'white',
        padding: '32px 30px',
        borderRadius: '3px',
        boxShadow: '0px 0px 3px #ccc',
        maxWidth: '1320px'
    },
    header: {
        color: '#96979a',
        textTransform: 'uppercase',
        fontSize: '17px',
        marginTop: '8px',
        fontFamily: 'AvenirNext-DemiBold'
    },
    subheader: {
        color: '#cfcfcf',
        textTransform: 'uppercase',
        fontSize: '13px',
        fontWeight: '400',
        marginTop: '8px'
    },
    row: {
    },
    section: {
        float: 'left',
        width: '50%',
        padding: '20px'
    },
    subsection: {
        float: 'left',
        width: '50%'
    },
    text: {
        fontFamily: 'Lato',
        fontSize: '13px',
        color: '#4a4a4a',
        marginTop: '3px',
        marginBottom: '8px'
    }

}
