import React, { Component } from 'react';
import moment from 'moment-timezone';
import { ThemeProvider } from 'styled-components';

// Styles
import styles, { Container, Row, Col, Text, Name, SmallText, Logo, Text_1, Text_2 } from './styles';

class AxlMiniShipmentBox extends Component {
    renderMainTheme(shipment) {
      return (
        <Row>
            <Col>
              <div>
                <Name>{ (shipment && shipment.customer) ? shipment.customer.name : '-' }</Name>
              </div>
              <div>
                <Text>{ `${moment(shipment.dropoff_earliest_ts).format('hh:mm A')} - ${moment(shipment.dropoff_latest_ts).format('hh:mm A')}` }</Text>
              </div>
            </Col>
            <Col>
              <div>
                <Text className={`text-right`}>{ (shipment && shipment.dropoff_address) ? shipment.dropoff_address.street : '-' }</Text>
              </div>
              <div>
                <Text className={`text-right`}>{ (shipment && shipment.dropoff_address) ? shipment.dropoff_address.city + ', ' + shipment.dropoff_address.state + ' ' + shipment.dropoff_address.zipcode : '' }</Text>
              </div>
            </Col>
        </Row>
      );
    }

    renderDefaulTheme(shipment) {
      return (
        <Row>
            <Col>
              <div>
                <Text>{ shipment.internal_id }</Text>
              </div>
              <div>
                <Name>{ (shipment && shipment.customer) ? shipment.customer.name : '-' }</Name>
              </div>
            </Col>
            <Col>
              <div>
                <Text className={`text-right`}>{ (shipment && shipment.dropoff_address) ? shipment.dropoff_address.street : '-' }</Text>
              </div>
              <div>
                <Text className={`text-right`}>{ (shipment && shipment.dropoff_address) ? shipment.dropoff_address.city + ', ' + shipment.dropoff_address.state + ' ' + shipment.dropoff_address.zipcode : '' }</Text>
              </div>
            </Col>
        </Row>
      );
    }

    render() {
        const { shipment } = this.props;

        return <ThemeProvider theme={{type: this.props.theme ? this.props.theme : 'default'}}>
          <Container
            onClick={() => this.props.onClick && this.props.onClick()}
            style={{...this.props.style, ...styles.statuses[this.props.status.replace(' ', '_')]}}>
            {(this.props.theme && this.props.theme !== 'default') ? this.renderMainTheme(shipment) : this.renderDefaulTheme(shipment)}
        </Container>
      </ThemeProvider>
    }


    static Secondary = class extends Component {
      render() {
        const { shipment } = this.props;

        return <ThemeProvider theme={{type: this.props.theme ? this.props.theme : 'default'}}>
            <Container
              onClick={() => this.props.onClick && this.props.onClick()}
              style={{...this.props.style, ...styles.statuses[this.props.status.replace(' ', '_')]}}>
                <Row>
                  <Col><Text_1>{ `${moment(shipment.dropoff_earliest_ts).format('hh:mm A')} - ${moment(shipment.dropoff_latest_ts).format('hh:mm A')}` }</Text_1></Col>
                  <Col />
                </Row>
                <Row>
                  <Col><Name>{ (shipment && shipment.customer) ? shipment.customer.name : '-' }</Name></Col>
                  <Col><Text className={`text-right`}>{ (shipment && shipment.dropoff_address) ? shipment.dropoff_address.street : '-' }</Text></Col>
                </Row>
                <Row>
                  <Col><Text_2>{(shipment && shipment.internal_id) ? shipment.internal_id : '-'}</Text_2></Col>
                  <Col><Text className={`text-right`}>{ (shipment && shipment.dropoff_address) ? shipment.dropoff_address.city + ', ' + shipment.dropoff_address.state + ' ' + shipment.dropoff_address.zipcode : '' }</Text></Col>
                </Row>
          </Container>
        </ThemeProvider>
      }
    }
}

export default AxlMiniShipmentBox
