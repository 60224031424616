import logo from '../images/svg/logo.svg';
import message from '../images/svg/message.svg';
import avatar from '../images/avatar.png';
import search from '../images/search.png';
import user from '../images/svg/user.svg';

export default {
  logo,
  message,
  avatar,
  search,
  user
}
