export const firebaseConfig = {
  apiKey: "AIzaSyA4WCvx4WbZV5VGEmTJwRJ-Li9qsW5eU3U",
  authDomain: "driver-app-v2-staging.firebaseapp.com",
  databaseURL: "https://driver-app-v2-staging.firebaseio.com",
  projectId: "driver-app-v2-staging",
  storageBucket: "driver-app-v2-staging.appspot.com",
  messagingSenderId: "15562989744",
  appId: "1:15562989744:web:2b5c1613a6a4bac7605b75",
  measurementId: "G-MD194M3Q4Q"
};

export const usePublicVapidKey = "BO86ysh1tFwXc9m7lyMsJH7JGJpShocG-cFnpnefI22qFWec9F6_6G0NmaMZQPz1KGZdS7W5gQF4varV055WJJM"

export default {}