const REGION_NAME = {
    sf: 'San Francisco',
    oc: 'Orange County',
    phx: 'Phoenix'
}

function region_name(code) {
    if (!code || code.length < 1)
        return '';
    let zones = code.split(',').map(z => REGION_NAME[z] ? REGION_NAME[z] : z)
        .map(z => z.replace('_', ' '.replace('-', ' / ')))
    // return zones.join(' / ')
    return zones
}

export { region_name }
