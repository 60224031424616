import styled from 'styled-components';

export default {
  dropdown_anchor: {
    left: {
      left: 0,
    },
    right: {
      right: 0,
    },
    top: {
      bottom: '100%',
    },
    bottom: {
      top: '100%'
    }
  }
}

export const DropDownMenuContainer = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 1px 2px #999;
  min-width: 100px;
`;

export const MenuItem = styled.div`
  padding: 8px 12px;
  color: #333;
  font-size: 14px;
  border-bottom: solid 1px #efefef;
  cursor: pointer;
  text-align: left;
  &:hover {
    background-color: #efefff;
  }
`;

export const DropDownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const LabelContainer = styled.div`
  position: relative;
  display: inline-block;
  opacity: ${props => props.disabled ? 0.3 : 1};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;
