import styled from 'styled-components';
import media from '../../styled-components/media';

export const Container = styled.div`
    background-color: white;
    height: 51px;
    position: relative;
    z-index: 1000;
`;

export const InnerContainer = styled.div`
    display: flex;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const HeaderLeft = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
`;

export const Title = styled.span`
  font-family: "AvenirNext-Medium";
  font-size: 12px;
  font-weight: 500;
  color: #2a2444;
`;

export const HeaderRight = styled.div`
    display: flex;
    justify-content: center;
    align-items: center
`;

export const Logo = styled.img`
    max-width: 61px;
    max-height: 51px;
    padding: 6px 10px;
    cursor: pointer;
    box-sizing: border-box;
`;

export const User = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    margin-right: 15px;
`;

export const NoAvatar = styled.div`
    width: 27px;
    height: 27px;
    background-color: #d8d8d8;
    margin-right: 10px;
    border-radius: 50%;
`;

export const Avatar = styled.img`
    width: 27px;
    height: 27px;
    margin-right: 10px;
    border-radius: 50%;
`;

export const Name = styled.span`
    margin-right: 10px;
    font-family: "AvenirNext";
    font-size: 13px;
    letter-spacing: -0.1px;
    text-align: center;
    color: #7a8396;
`;

export const Arrow = styled.span`
    margin-bottom: 5px;
    border-top: 4px solid transparent;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    border-bottom: 4px solid #9da5b6;
    &.active {
        border-top: 4px solid #9da5b6;
        border-bottom: 4px solid transparent;
        margin-bottom: -5px;
    }
`;

export const Dropdown = styled.div`
    position: absolute;
    padding: 0;
    height: auto;
    top: 100%;
    right: 0px;
    background: #FFF;
    border: 1px solid #ccc;
    margin-top: 5px;
    border-radius: 3px;
    min-width: 120px;
`;

export const Logout = styled.div`
    display: block;
    border: none;
    background: transparent;
    text-align: left;
    cursor: pointer;
    font-family: "AvenirNext";
    font-size: 13px;
    letter-spacing: -0.1px;
    color: #7a8396;
    padding: 10px 15px;
    border-bottom: 1px solid #CCC;
    background-color: #FFF;
    cursor: pointer;
    &:nth-last-child(1) {
        border-bottom: none;
    }
    &:hover {
        background-color: #f7f7f7;
    }
`;

export const MenuBar = styled.div`
    width: 16px;
    cursor: pointer;
    margin: 0 10px 0 0;
`;
export const MainMenuBar = styled.div`
    width: 16px;
    cursor: pointer;
    margin: 0;
    ${media.md`display: none;`};
`;

export const IconBar = styled.div`
    width: 100%;
    height: 2px;
    background-color: #2a2444;
    margin: 3px 0;
`;

export default {
    themes: {
        dark: {
            backgroundColor: '#333',
            color: '#eee',
            fontWeight: 300
        },
        light: {
            backgroundColor: '#fff',
            color: '#222'
        }
    }
}
