export const STATUS = {
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  UNASSIGNED: 'UNASSIGNED',
  READY: 'READY',
};

export const DRIVER_STATUS = {
  QUIT: 'QUIT',
  CREATED: 'CREATED',
  SUSPENDED: 'SUSPENDED',
};

export const DRIVER_BACKGROUND_STATUS = {
  AUTO_APPROVED: 'AUTO_APPROVED',
  MANUAL_APPROVED: 'MANUAL_APPROVED',
  REJECTED: 'REJECTED',
  DRIVER_CONSENTED: 'DRIVER_CONSENTED',
  PENDING: 'PENDING',
};

export const DRIVER_REGISTRATION_STATUS = {
  QUIZ_SENT: 'QUIZ_SENT',
  QUIZ_COMPLETED: 'QUIZ_COMPLETED',
};

export const NOT_ALLOW_APPROVE_DRIVER_STATUS = [DRIVER_BACKGROUND_STATUS.AUTO_APPROVED, DRIVER_BACKGROUND_STATUS.MANUAL_APPROVED];

export const NOT_ALLOW_REQUIZ_DRIVER_STATUS = [DRIVER_REGISTRATION_STATUS.QUIZ_COMPLETED];

export const NOT_ALLOW_RESEND_BACKGROUND_REQUEST_URL = [DRIVER_BACKGROUND_STATUS.AUTO_APPROVED, DRIVER_BACKGROUND_STATUS.MANUAL_APPROVED, DRIVER_BACKGROUND_STATUS.REJECTED];

export const ASSIGNMENT_STATUS = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  NULL: null,
};

export const STOP_STATUS = {
  PENDING: 'PENDING',
  SUCCEEDED: 'SUCCEEDED',
  REATTEMPT: 'REATTEMPT',
  FAILED: 'FAILED',
  READY: 'READY',
  EN_ROUTE: 'EN_ROUTE',
};

export const FILTER_ASSIGNMENT_STATUS = ['all', 'unassigned', 'pending', 'on_the_way', 'picking_up', 'inactive', 'at_risk', 'in_progress', 'completed'];
