import { observable, action, computed, makeObservable } from 'mobx';
import _ from 'lodash';

class WorkwhileStore {
    constructor(api) {
        makeObservable(this, {
            activeShifts: observable,
            positions: observable,
            locations: observable,
            errors: observable,
            loadingShifts: observable,
            currentPage: observable,
            limit: observable,
            currentShiftId: observable,
            currentShift: observable,
            loadingShift: observable,
            loadingLocations: observable,
            locations: observable,
            warehouseId: observable,
            linkingDriver: observable,
            getActiveShifts: action,
            searchQuery: computed,
            getShift: action,
            refreshShift: action,
            addAssignment: action,
            updateWorkerNeeded: action,
            selectWarehouse: action,
            getLocations: action,
            linkDriver: action
        });

        this.api = api;
    }

    activeShifts = null;
    positions = [];
    locations = [];
    errors = {};
    loadingShifts = false;
    currentPage = 1;
    limit = 20;

    currentShiftId = null;
    currentShift = null;
    loadingShift = false;

    loadingLocations = false;
    locations = [];
    warehouseId = '';
    
    linkingDriver = false;

    tokens = {}

    getActiveShifts(page) {
        this.currentPage = page;
        this.loadingShifts = true;
        return this.api.get(`/external/workwhile/shift?page=${page || 1}&size=${this.limit}` + (this.warehouseId ? `&warehouse=${this.warehouseId}` : ''))
        .then((response) => {
            this.loadingShifts = false
            this.activeShifts = response.data
        })
    }

    get searchQuery() {
        let q = {}
        if (this.currentPage > 1) {
            q['page'] = this.currentPage
        }
        if (this.warehouseId) {
            q['warehouse'] =  this.warehouseId
        }
        return q
    }

    getShift(id) {
        this.currentShiftId = id;
        this.currentShift =  null;
        this.loadingShift = true;
        return this.api.get(`/external/workwhile/shift/${id}/detail`)
        .then((response) => {
            this.loadingShift = false
            if (response.status == 200) {
                this.currentShift = this.processData(response.data)
            }
        })
    }

    refreshShift() {
        if (!this.currentShiftId) return
        this.loadingShift = true;
        return this.api.get(`/external/workwhile/shift/${this.currentShiftId}/detail`)
        .then((response) => {
            this.loadingShift = false
            if (response.status == 200) {
                this.currentShift = this.processData(response.data)
            }
        })
    }

    processData(shift) {
        let driverMap = {}
        if (shift.drivers) {
            for (let driver of shift.drivers) {
                driverMap[driver.id] =  driver
            }
        }
        for (let w of shift.shift.workItems) {
            if (w.worker && w.worker.internal_id) {
                w.driver = driverMap[w.worker.internal_id]
            }
        }
        return shift;
    }

    addAssignment(assignmentIds) {
        if (!this.currentShift) return
        this.loadingShift = true
        return this.api.post(`/external/workwhile/shift/${this.currentShift.id}/assignments`, {ids: assignmentIds})
        .then((response) => {
            this.loadingShift = false
            if (response.status == 200) {
                this.currentShift = this.processData(response.data)
                return true;
            }
            return false;
        })
    }

    updateWorkerNeeded(workerNeeded) {
        if (!this.currentShift) return
        if (this.currentShift.shift.workerNeeded >= workerNeeded) {
            alert('Cannot update worker needed to a number smaller than booked workers');
            return
        }
        this.loadingShift = true
        return this.api.put(`/external/workwhile/shift/${this.currentShift.id}/worker-needed`, workerNeeded)
        .then((response) => {
            this.loadingShift = false
            if (response.status == 200) {
                this.currentShift = this.processData(response.data)
                return true;
            }
            return false;
        })
    }

    selectWarehouse(warehouse_id) {
        if (warehouse_id !== this.warehouseId) {
            this.warehouseId = warehouse_id
        }
    }

    getLocations() {
        this.loadingLocations = true;
        const url = `/external/workwhile/locations`
        return this.api.get(url)
        .then((response) => {
            this.loadingLocations = false
            this.locations = response.data
        })
    }

    linkDriver(workerId, driverId) {
        this.linkingDriver = true
        const url = `/external/workwhile/workers/${workerId}/link`
        return this.api.post(url, driverId).then((response) => {
            this.linkingDriver = false
            if (response.status === 200)
                return response.data
            return null
        })
    }

}

export default WorkwhileStore
