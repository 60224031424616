import React, { Component, cloneElement } from 'react';

// Styles
import { Container } from './styles';

export default class AxlPanelSlider extends Component {
  render() {
    return <Container style={this.props.style}>{this.props.children}</Container>
  }
}
