import React, { Component } from 'react';
import styles, { Container, TextInput } from './styles';
import { ThemeProvider } from 'styled-components';

class AxlTextArea extends Component {
  constructor(props) {
    super(props)
    this.onKeyUp = this.onKeyUp.bind(this)
  }
  noop() {}

  onKeyUp(event) {
    this.props.onEnter && event.key === 'Enter' && this.props.onEnter()
    this.props.onKeyPress && this.onKeyPress(event)
  }

  render() {
    return <ThemeProvider theme={{type: this.props.theme ? this.props.theme : 'default'}}>
      <Container style={{...this.props.containerStyle}}>
        <TextInput
          placeholder={this.props.placeholder}
          onChange={ this.props.onChange ? this.props.onChange : this.noop }
          onKeyUp={ this.onKeyUp }
          defaultValue={ this.props.defaultValue }
          value={ this.props.value }
          name={this.props.name ? this.props.name: undefined}
          type={ this.props.type }
          style={{...{width: this.props.fluid ? '100%' : 'auto'},...styles.container, ...this.props.style}} />
      </Container>
    </ThemeProvider>
  }
}

export default AxlTextArea
