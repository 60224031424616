import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
} from 'react-router-dom'

// Styles
import { Container, Row, FlexBar, FlexMenu, FlexMenuSecond, List, ListSecond, Item, ItemSecond, LinkItem, BarToggle, BarIcon, LinkLogo } from './styles';
import images from '../../styled-components/images';

export default class AxlBasicHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowSecondMenu: false
    }
    this.showSecondMenu = this.showSecondMenu.bind(this);
  }

  showSecondMenu() {
    this.setState({isShowSecondMenu: !this.state.isShowSecondMenu})
  }

  render() {
    return <Container>
      <Router>
        <Row>
          <LinkLogo href="/" target="_blank"><img src={images.logo} width={154} height={52} /></LinkLogo>
          <FlexBar>
            <BarToggle onClick={this.showSecondMenu}>
              <BarIcon />
              <BarIcon />
              <BarIcon />
            </BarToggle>
          </FlexBar>
          <FlexMenu>
              <List>
                <Item><LinkItem href="https://axlehire.com/services" target="_blank">{`SERVICES`}</LinkItem></Item>
                <Item><LinkItem href="https://axlehire.com/industry" target="_blank">{`INDUSTRIES`}</LinkItem></Item>
                <Item><LinkItem href="https://axlehire.com/tracking" target="_blank" className={`active`}>{`TRACKING`}</LinkItem></Item>
                <Item><LinkItem href="https://axlehire.com/api" target="_blank">{`API`}</LinkItem></Item>
                {/*<Item><LinkItem href="https://axlehire.com/contact-us" target="_blank">{`CONTACT`}</LinkItem></Item>*/}
              </List>
          </FlexMenu>
        </Row>
        {this.state.isShowSecondMenu && <Row>
          <FlexMenuSecond>
              <ListSecond>
                <ItemSecond><LinkItem href="https://axlehire.com/services" target="_blank">{`SERVICES`}</LinkItem></ItemSecond>
                <ItemSecond><LinkItem href="https://axlehire.com/industry" target="_blank">{`INDUSTRIES`}</LinkItem></ItemSecond>
                <ItemSecond><LinkItem href="https://axlehire.com/tracking" target="_blank" className={`active`}>{`TRACKING`}</LinkItem></ItemSecond>
                <ItemSecond><LinkItem href="https://axlehire.com/api" target="_blank">{`API`}</LinkItem></ItemSecond>
                {/*<ItemSecond><LinkItem href="https://axlehire.com/contact-us" target="_blank">{`CONTACT`}</LinkItem></ItemSecond>*/}
              </ListSecond>
          </FlexMenuSecond>
        </Row>}
      </Router>
    </Container>
  }
}
