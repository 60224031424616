import styled from "styled-components";

export const Container = styled.div``;
export const TypingContainer = styled.div`
  padding: 10px 15px;
  text-align: left;
`;
export const TypingText = styled.div`
  font-family: 'AvenirNext-DemiBold';
  font-size: 9.5px;
  font-weight: 600;
  text-align: left;
  color: #393060;
`;
export const FormChat = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  border-top: solid 1px rgba(151, 151, 151, 0.3);
`;
export const ImagePicker = styled.input`
  width: 40px;
  cursor: pointer;
  outline: none;
  margin-top: -5px;
`;
export const ImageLink = styled.input`
  width: 40px;
  cursor: pointer;
  outline: none;
  margin-top: -5px;
`;
export const TextInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  height: 25px;
  margin: 0 20px;
  font-family: 'AvenirNext-Medium';
  font-size: 16px;
  font-weight: 500;
  color: #8d8d8d;
  resize: none;
  ::-webkit-input-placeholder {
    font-family: 'AvenirNext-Medium';
    font-size: 16px;
    font-weight: 500;
    color: #8d8d8d;
  }
  ::-moz-placeholder {
    font-family: 'AvenirNext-Medium';
    font-size: 16px;
    font-weight: 500;
    color: #8d8d8d;
  }
  :-ms-input-placeholder {
    font-family: 'AvenirNext-Medium';
    font-size: 16px;
    font-weight: 500;
    color: #8d8d8d;
  }
  :-moz-placeholder {
    font-family: 'AvenirNext-Medium';
    font-size: 16px;
    font-weight: 500;
    color: #8d8d8d;
  }
`;
export const SubmitButton = styled.button`
  font-family: 'AvenirNext-Bold';
  font-size: 20px;
  font-weight: bold;
  color: #6c62f5;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
`;

export const ImageContainer = styled.div`
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
`;

export const ImagePreviews = styled.div`
  padding: 3px;
  width: 50px;
  height: 50px;
  border: 1px solid #8078ee;
  border-radius: 3px;
  margin: 0 3px;
  position: relative;
`;

export const ImagePreview = styled.img`
  width: auto;
  max-width: 50px;
  height: 50px;
`;

export const CloseImage = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
  background: #000;
  font-size: 9px;
  line-height: 12px;
  color: #FFF;
  cursor: pointer;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #ffffff80;
  border-radius: 3px;
`;

export default {}