import styled from 'styled-components';

const PlaceHolder = {
  default: `
    font-family: AvenirNext;
    font-size: 14px;
    letter-spacing: 0.4px;
    color: #bfbfbf;
  `,
  main: ``,
  gray:`
    font-family: AvenirNext;
    font-size: 14px;
    letter-spacing: 0.4px;
    color: #bfbfbf;
  `
}

const placeHolder = (styles) => {
  return `
    &::-webkit-input-placeholder { ${styles} }
    &::-moz-placeholder { ${styles} }
    &:-ms-input-placeholder { ${styles} }
    &:-moz-placeholder { ${styles} }
  `
}

const ContainerStyle = {
  default: ``,
  main: ``,
  gray: ``
};

const TextInputStyle = {
  default: `
    background-color: #FFF;
    font-size: 17px;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 4px 14px 4px 8px;
    border: solid 1px #ccc;
    outline: none;
    ${placeHolder(PlaceHolder.default)}
  `,
  main: ``,
  gray: `
    font-family: 'AvenirNext-Medium';
    font-size: 13px;
    font-weight: 500;
    color: #8d8d8d;
    box-sizing: border-box;
    padding: 4px 14px 4px 8px;
    outline: none;
    border-radius: 4px;
    box-shadow: 2px 2px 6px 1px #00000025;
    border: solid 1px #aeaeae;
    background-color: #cccccc1d;
    min-height: 120px;
    resize: vertical;
    ${placeHolder(PlaceHolder.default)}
  `
};

export const Container = styled.div`${props => props.theme.type ? ContainerStyle[props.theme.type] : ContainerStyle.default}`;

export const TextInput = styled.textarea`${props => props.theme.type ? TextInputStyle[props.theme.type] : TextInputStyle.default}`;


export default {}
