const Colors = {
    white: '#FFF',
    default: '#3b3b3b',
    gray: '#bebfc0',
    greyOne: '#fafafa',
    yellow: '#fecf33',
    lightOrange: '#fdbd39',
    peach: '#ee6723',
    periwinkle: '#887fff',
    darkPastelGreen: '#4abc4e',
    maize: '#fbc04f',
    dustyRed: '#d63031',
    mediumGrey: '#4a4a4a',
    lightGrey: '#96979a',
    veryLightGrey: '#f4f4f4',
    bluish: '#297ec8',
    lightPeriwinkle: '#e3e5ea',
    reddishOrange: '#fa6725',
    veryLightPink: '#e4e4e4',
    blueyGrey: '#9da5b6',
    redishOrange: '#fa6725',
    lightBlue: '#a1c5ef',
    lightBlueTwo: '#f4f3ff',
    pinkishGrey: '#cccccc',
    warmGrey: '#9b9b9b'
}

const STATUS_COLOR_CODE = {
    PENDING: Colors.reddishOrange,
    IN_PROGRESS: Colors.lightOrange,
    COMPLETED: Colors.darkPastelGreen,
    SUCCEEDED: Colors.darkPastelGreen,
    FAILED: Colors.dustyRed,
    LATE: '#8447f6',
    EARLY: '#a5ccec',
}

const INBOUND_STATUS_COLOR_CODE = {
    RECEIVED_OK: Colors.darkPastelGreen,
    RECEIVED_DAMAGED: Colors.reddishOrange,
    MISSING: Colors.dustyRed,
    UNSCANNED: Colors.lightGrey
}

export { Colors, STATUS_COLOR_CODE, INBOUND_STATUS_COLOR_CODE }
