import { observable, action, makeObservable } from 'mobx';
import _ from 'lodash';
class CallCenterStore {
  constructor(api) {
    makeObservable(this, {
      assignmentLoading: observable,
      selectedAssignment: observable,
      loadAssignment: action,
      generatingSession: observable,
      callSession: observable
    });

    this.api = api;
  }

  //Todo
  assignmentLoading = false;
  selectedAssignment = null;

  loadAssignment(id = null, cb = () => {}) {
    if(!id) return false;

    this.assignmentLoading = true;

    this.api.get(`/assignments/${id}/detail?show_soft_deleted=true`).then(res => {
      if(res.ok || res.status === 200) {
        this.selectedAssignment = res.data;
      }

      if(cb) cb(res);

      this.assignmentLoading = false;
    })
  }

  //Todo generateSession
  generatingSession = false;
  callSession = null;

  generateSession(callObject = {}, cb = () => {}) {
    this.generatingSession = true;

    this.api.post(`/call_center/`, callObject).then(res => {
      if(res.ok || res.status === 200) {
        this.callSession = res.data;
      }

      this.generatingSession = false;

      if(cb) cb(res);
    })
  }
}

export default  CallCenterStore
