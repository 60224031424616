import styled from 'styled-components';
import media from '../../styled-components/media';

export const Container = styled.div`
  display: block;
  box-shadow: 0 2px 5px 0 rgba(194, 194, 194, 0.5);
  background-color: #2a2444;
  &.show {
    //display: block;
  }
  &.hide {
    //display: none;
  }
  ${media.md`
    &.show {
      //display: block;
    }
    &.hide {
      //display: block;
    }
  `}
`;

export const ContainerInner = styled.div`
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 999;
  padding: 0;
  padding-right: 0;
  height: 50px;
  overflow: hidden;
`;

export const MenuList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  padding-inline-start: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;
  height: 67px;
  overflow-x: scroll;
  overflow-y: hidden;
`;

export const MenuItem = styled.li`
  padding: 0 20px;
  cursor: pointer;
  display: block;
  font-family: AvenirNext;
  font-size: 12px;
  text-align: right;
  color: #ffffff;
  line-height: 40px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  transition: all 0.3s ease 0s;
  position: relative;
  width: 150px;
  display: inline-block;
  text-align: center;
  padding: 0;
  min-width: 150px;
  &:hover {
    background: #332c4e;
    ul {
      display: block;
    }
  }
  ${media.md`
    flex: 1;
    display: inline-block;
    text-align: center;
    padding: 0;
    &.active {
      border-bottom-color: #FFF;
    }
  `}
`;

export const MenuListParent = styled.ul`
  list-style: none;
  padding: 0;
  padding-inline-start: 0;
  position: absolute;
  background: #FFF;
  margin: 5px 0;
  box-shadow: 0 4px 7px 2px rgba(92, 89, 92, 0.5);
  border: solid 1px rgba(109, 107, 109, 0.63);
  background-color: #ffffff;
  display: none;
`;

export const MenuItemChild = styled(MenuItem)`
  color: #8d8d8d;
  border-top: 1px solid transparent;
  border-bottom: solid 1px rgba(109, 107, 109, 0.63);
  &:hover {
    background: #FFF;
  }
  &:nth-last-child(1) {
    border-bottom: none;
  }
`;
