import styled from 'styled-components';

const PlaceHolder = {
  default: `
    font-family: AvenirNext;
    font-size: 14px;
    letter-spacing: 0.4px;
    color: #bfbfbf;
  `,
  main: ``,
  periwinkle: `
    font-family: 'AvenirNext';
    font-size: 14px;
    text-align: left;
    color: #4a4a4a;
  `,
  gray: `
    font-family: 'AvenirNext';
    font-size: 14px;
    text-align: left;
    color: #4a4a4a;
  `,
  numberMini: ``
}

const placeHolder = (styles) => {
  return `
    &::-webkit-input-placeholder { ${styles} }
    &::-moz-placeholder { ${styles} }
    &:-ms-input-placeholder { ${styles} }
    &:-moz-placeholder { ${styles} }
  `
}

const ContainerStyle = {
  default: ``,
  main: ``,
  periwinkle: ``,
  gray: ``,
  numberMini: ``,
};

const TextInputStyle = {
  default: `
    background-color: #FFF;
    height: 39px;
    font-size: 17px;
    line-height: 36px;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 4px 14px 4px 8px;
    border: solid 1px #ccc;
    outline: none;
    ${placeHolder(PlaceHolder.default)}
  `,
  main: ``,
  periwinkle: `
    height: 46px;
    border-radius: 3px;
    border: solid 1px #8c83ff;
    background-color: #f2f1ff;
    padding: 10px 20px;
    max-width: 100%;
    min-width: 195px;
    text-align: left;
    ${placeHolder(PlaceHolder.periwinkle)}
  `,
  gray: `
    height: 46px;
    font-size: 13px;
    line-height: 36px;
    box-sizing: border-box;
    padding: 4px 14px 4px 8px;
    outline: none;
    border-radius: 4px;
    box-shadow: 2px 2px 6px 1px #00000025;
    border: solid 1px #aeaeae;
    background-color: #cccccc1d;
    font-family: 'AvenirNext-Medium';
    font-weight: 500;
    color: #8d8d8d;
    ${placeHolder(PlaceHolder.default)}
  `,
  numberMini: `
    padding: 3px;
    box-shadow: none;
    border-radius: 3px;
    background-color: #f9f9f9;
    border: 1px solid #e4e4e4;
    box-sizing: border-box;
    ${placeHolder(PlaceHolder.default)}
  `
};

const TextInputErrorStyle = {
  default: `
    border: solid 1px red;
    outline: none;
  `,
  main: `
    border: solid 1px red;
    outline: none;
  `,
  periwinkle: ``,
  gray: `
    border: solid 1px #d0021b;
    outline: none;
  `,
  numberMini: `
    border: solid 1px #d0021b;
    outline: none;
  `
};

const ErrorStyle = {
  default: `
    color: red;
    margin: 5px 0 0;
    font-size: 11px;
  `,
  main: ``,
  periwinkle: ``,
  gray: `
    color: #d0021b;
    margin: 5px 0 0;
    font-size: 11px;
    margin-bottom: 3px;
  `,
  numberMini: `
    color: #d0021b;
    margin: 5px 0 0;
    font-size: 11px;
    margin-bottom: 3px;
  `
};

const InputWrapStyle = {
  default: `
    position: relative;
  `,
  main: `
    position: relative;
  `,
  periwinkle: `
    position: relative;
  `,
  gray: `
    position: relative;
  `,
  numberMini: `
    position: relative;
  `
};

const ErrorIconStyle = {
  default: `
    color: red;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    width: 14px;
    height: 16px;
    line-height: 16px;
  `,
  main: `
    color: red;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    width: 14px;
    height: 16px;
    line-height: 16px;
  `,
  periwinkle: `
    color: red;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    width: 14px;
    height: 16px;
    line-height: 16px;
  `,
  gray: `
    color: #d0021b;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    width: 14px;
    height: 16px;
    line-height: 16px;
  `,
  numberMini: `
    color: #d0021b;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    width: 14px;
    height: 16px;
    line-height: 16px;
  `
};

const errorIconStyle = {
  default: `
    padding-right: 30px;
  `,
  main: `
    padding-right: 30px;
  `,
  periwinkle: `
    padding-right: 30px;
  `,
  gray: `
    padding-right: 30px;
  `,
  numberMini: `
    padding-right: 30px;
  `,
}

export const Container = styled.div`${props => props.theme.type ? ContainerStyle[props.theme.type] : ContainerStyle.default}`;
export const TextInput = styled.input`
  ${props => props.theme.type ? TextInputStyle[props.theme.type] : TextInputStyle.default}
  ${props => props.isError ? props.theme.type ? TextInputErrorStyle[props.theme.type] : TextInputErrorStyle.default : {}}
  ${props => props.errorIcon ? props.theme.type ? errorIconStyle[props.theme.type] : errorIconStyle.default : {}}
`;
export const InputWrap = styled.div`${props => props.theme.type ? InputWrapStyle[props.theme.type] : InputWrapStyle.default}`;
export const Error = styled.p`${props => props.theme.type ? ErrorStyle[props.theme.type] : ErrorStyle.default}`;
export const ErrorIcon = styled.i`${props => props.theme.type ? ErrorIconStyle[props.theme.type] : ErrorIconStyle.default}`;

export default {}
