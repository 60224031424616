import styled from 'styled-components';
import { STATUS_COLOR_CODE, Colors } from '../../constants/Colors';

export const Container = styled.div`
    background-color: white;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 10px 15px;
    line-height: 1.5em;
    font-size: 15px;
    border: 1px solid ${Colors.veryLightPink};
    min-height: 44px;
    margin: 10px 1px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    text-align: left;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Col = styled.div`
    flex: 1
`;

export const Logo = styled.div`
    position: absolute;
    top: 14px;
    left: 4px;
    width: 32px;
    height: 32px;
`;

export const Text = styled.div`
    font-family: 'AvenirNext-Medium';
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    color: #3b3b3b;
    margin-bottom: 5px;

    &.text-right { text-align: right; }
    &.text-center { text-align: center; }
    &.text-left { text-align: left; }
`;

export const Error = styled(Text)`
    font-weight: bold;
    color: ${Colors.dustyRed};
`;

export const Processing = styled(Text)`
    font-family: 'AvenirNext-Bold';
`;

export const TextRight = styled(Text)`
    text-align: right;
`;

export const SmallText = styled.span`
  font-family: 'AvenirNext-Medium';
  font-size: 11px;
  font-weight: 300;
  color: #55a;
  display: 'inline-block';
`;

export const Right = styled.div`
    text-align: right;
`;

export const ProcessBar = styled.div`
    border-radius: 5px;
    background-color: #ededed;
    width: 100%;
    height: 15px;
    position: relative;
    overflow: hidden;
`;

export const Bar = styled.div`
    width: 0%;
    height: 100%;
    background-color: ${Colors.periwinkle};
`;

export default {
    statuses: {
        PENDING: {
            borderRightColor: STATUS_COLOR_CODE.PENDING
        },
        SUCCEEDED: {
            borderRightColor: STATUS_COLOR_CODE.SUCCEEDED
        },
        FAILED: {
            borderRightColor: STATUS_COLOR_CODE.FAILED
        }
    }
}
