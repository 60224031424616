import React, { Component } from 'react';

// Styles
import { Container, RowPanel, ColPanel } from './styles';

export default class AxlPanel extends Component {
  render() {
    return <Container {...this.props}>
      {this.props.children}
    </Container>
  }

  static Row = class extends Component {
    render () {
      const flexDirection = this.props.col ? { flexDirection: 'column' } : {}

      return <RowPanel style={{alignItems: this.props.align, justifyContent: this.props.align, ...flexDirection, ...this.props.style}}>
        {this.props.children}
      </RowPanel>
    }
  }

  static Col = class extends Component {
    render () {
      const flexStyles = this.props.flex === 0 ? {flexGrow: 0, flexShrink: 0, flexBasis: 'auto'} : {flex: this.props.flex}
      const centerStyles = this.props.center ? {textAlign: 'center'} : {}

      return <ColPanel style={{...flexStyles, ...centerStyles, ...this.props.style}}>
        {this.props.children}
      </ColPanel>
    }
  }
}
