import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

// Styles
import styles, * as E from './styles';

export default class AxlReselect extends Component {
  render() {
    const props = _.omit(this.props, ['components', 'styles', 'theme', 'error', 'showError'])

    const DropdownIndicator = (props) => {
      return components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <E.DropdownIndicatorStyle />
        </components.DropdownIndicator>
      );
    };

    const defaultStyle = this.props.theme ? styles[this.props.theme] : styles.default

    const customStyles = {
      container: () => defaultStyle.container,
      option: (styles, { data }) => {
        return {
          ...defaultStyle.option,
          color: data.color
        }
      },
      control: () => {
        return {
          ...defaultStyle.control,
          ...!!this.props.error ? {borderColor: 'red'} : {}
        }
      },
      singleValue: () => defaultStyle.singleValue,
      valueContainer: () => defaultStyle.valueContainer,
      menuList: () => defaultStyle.menuList,
      menu: () => defaultStyle.menu,
      menuPortal: () => defaultStyle.menuPortal,
      input: () => defaultStyle.input,
      indicatorsContainer: () => defaultStyle.indicatorsContainer,
      indicatorSeparator: () => defaultStyle.indicatorSeparator,
      // loadingIndicator: () => defaultStyle.loadingIndicator,
      dropdownIndicator: () => defaultStyle.dropdownIndicator,
      placeholder: () => defaultStyle.placeholder
    }

    return <E.Container>
      <Select
        isError={!!this.props.error}
        styles={customStyles}
        components={{ DropdownIndicator }}
        {...props} />
      {this.props.error && this.props.showError && <E.ErrorText>{this.props.error}</E.ErrorText>}
    </E.Container>
  }
}

AxlReselect.propTypes = {
  showError: PropTypes.bool
}

AxlReselect.defaultProps = {
 showError: true
}
