import styled from 'styled-components';

export const Container = styled.div``;

export const RowPanel = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ColPanel = styled.div`
  flex: 1;
`;
