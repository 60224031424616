import styled from 'styled-components';

import { Colors } from '../../constants/Colors';

export const Container = styled.div``;

export const ErrorText = styled.div`
  color: red;
  margin: 5px 0 0;
  font-size: 11px;
`;

export const DropdownIndicatorStyle = styled.span`
  width: 0;
  height: 0;
  display: inline-block;
  border-top: 5px solid #9da5b6;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
`;

const defaultStyle = {
  container: {
    height: 45,
    marginRight: 10
  },
  option: {
    padding: '13px 14px',
    borderBottom: '1px solid #e3e5ea',
    color: Colors.blueyGrey,
    fontSize: 14,
    fontFamily: 'Lato-Bold',
    textAlign: 'left',
    cursor: 'pointer'
  },
  control: {
    minWidth: 180,
    flex: 1,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#CCC',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    borderSizing: 'border-box',
    padding: '15px 14px',
    borderRadius: 3,
    whiteSpace: 'nowrap'
  },
  menu: {
    margin: '5px 0',
    position: 'relative',
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#CCC',
    zIndex: 999,
    borderRadius: 3
  },
  menuList: {
    maxHeight: 120,
    overflowX: 'auto'
  },
  menuPortal: {},
  input: {
    backgroundColor: 'red'
  },
  placeholder: {
    backgroundColor: 'green'
  },
  valueContainer: {
    flex: 1,
    textAlign: 'left',
    position: 'relative',
    height: 17
  },
  input: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'transparent',
    maxWidth: '100%',
    overflow: 'hidden'
  },
  indicatorsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  indicatorSeparator: {},
  // loadingIndicator: {},
  dropdownIndicator: {},
  singleValue: {
    color: Colors.blueyGrey,
    fontSize: 14,
    fontFamily: 'Lato-Bold',
    textAlign: 'left',
    whiteSpace: 'nowrap'
  },
  placeholder: {
    color: Colors.blueyGrey,
    fontSize: 14,
    fontFamily: 'Lato-Bold'
  }
}

export default {
  default: {
    ...defaultStyle
  },
  main: {
    ...defaultStyle,
    container: {
      height: '39px',
      marginRigth: 10
    },
    singleValue: {
      color: Colors.default,
      fontSize: 17,
      fontFamily: 'Arial, Tahoma, Sans-serif',
      textAlign: 'left',
      whiteSpace: 'nowrap'
    },
    option: {
      padding: '13px 14px',
      borderBottom: '1px solid #e3e5ea',
      color: '#bfbfbf',
      fontSize: 14,
      fontFamily: 'AvenirNext',
      textAlign: 'left',
      cursor: 'pointer'
    },
    placeholder: {
      fontFamily: 'AvenirNext',
      fontSize: '14px',
      letterSpacing: '0.4px',
      color: '#bfbfbf'
    }
  }
}
