export default {
    container: {},
    sectionHeader: {
        textAlign: 'left',
        fontSize: '12px',
        color: '#848484',
        fontFamily: 'AvenirNext',
        paddingTop: '10px',
        paddingBottom: '5px',
        display: 'flex',
        position: 'relative',
        cursor: 'pointer',
        width: '100%',
        maxWidth: '422px',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    sectionName: {
        paddingRight: '8px',
        zIndex: 1
    },
    sectionHeaderLine: {
        height: '1px',
        backgroundColor: '#ddd',
        zIndex: 0,
        display: 'flex',
        flex: 1,
        margin: '0 10px 0 0'
    },
    statusIndicator: {
        display: 'inline-block',
        marginRight: '4px',
        marginBottom: '3px',
        width: '10px',
        height: '10px',
        borderRadius: '5px',
        verticalAlign: 'middle'
    },
    arrow: {},
    arrowUp: {
        width: 0,
        height: 0,
        display: 'inline-block',
        borderLeft: '4px solid transparent',
        borderRight: '4px solid transparent',
        borderBottom: '5px solid black'
    },
    arrowDown: {
        width: 0,
        height: 0,
        display: 'inline-block',
        borderLeft: '4px solid transparent',
        borderRight: '4px solid transparent',
        borderTop: '5px solid black'
    }
}
