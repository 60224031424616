import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;
export const Inner = styled.div`
  position: absolute;
  bottom: calc(100% + 10px);
  padding: 0px 6px;
  background: #000;
  color: #FFF;
  border-radius: 3px;
  font-size: 11px;
`;
export const Title = styled.div``;
export const InnerTitle = styled.div`
  postion: relative;
`;
export const Arrow = styled.div`
  display: inline-block;
  border-bottom: 5px solid transparent;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-top: 5px solid #000;
  position: absolute;
  bottom: -10px;
`;
export const Child = styled.div``;

export default {}
