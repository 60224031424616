import React, { Component } from 'react';
import { Colors } from '../../constants/Colors';
import styles, { Container, Row, Col, Text, SmallText, Logo, TextRight, Error, ProcessBar, Bar } from './styles';
import Moment from 'react-moment';

class AxlMiniJobBox extends Component {
    selectJob = (e) => {
      const {job, onSelectJob} = this.props;
      if (onSelectJob) {
        onSelectJob(job);
      }
    };

    render() {
        const {job, displayProgressBar, percent} = this.props;
        const boxColor = { borderColor: (!!job.error_count && job.error_count > 0) ? Colors.dustyRed : Colors.veryLightPink };

        return <Container onClick={this.selectJob} style={{ ...this.props.containerStyle, ...boxColor }}>
            <Row>
                <Col>
                  <div>
                    <Text>{`${job.shipment_count} Shipments`}</Text>
                  </div>
                  <div>
                    {(!!job.error_count && job.error_count > 0) && <Error>{` ${job.error_count} ERRORS `}</Error>}
                    <strong>{job.name}</strong>
                  </div>
                </Col>
                <Col>
                  <div>
                    <TextRight><Moment interval={0} format={'MM/DD/YYYY @ HH:mm:ss'}>{ job.dropoff_earliest_ts }</Moment></TextRight>
                  </div>
                  <div>
                    <TextRight>{` ${job.status} `}</TextRight>
                  </div>
                </Col>
            </Row>
            <Row>
              {displayProgressBar && <ProcessBar><Bar style={{width: `${percent ? percent : '10'}%`}} />ssss</ProcessBar>}
            </Row>
        </Container>
    }
}

export default AxlMiniJobBox
