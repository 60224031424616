import React, { Component } from 'react';
import _ from 'lodash';

// Components
import AxlLoading from '../AxlLoading';

// Styles
import styles, { Container, Table, TH, LoadingContainer } from './styles';

class AxlTable extends Component {
  render () {
    const loadingStyle = this.props.loading ? styles.loading : {}

    return <Container className={this.props.isLoading ? `loading` : ``}>
      {this.props.isLoading && <AxlLoading mode={`large`} style={styles.LoadingContainer} />}
      <Table style={{ ...this.props.style }}>
        {this.props.children}
      </Table>
    </Container>
  }

  static Header = class extends Component {
    render () {
      return (<thead>{this.props.children}</thead>)
    }
  }
  static HeaderCell = class extends Component {
    render () {
      let _classNames = [];
      let classNames = [];
      const props = _.omit(this.props, ['up', 'isActive', 'children']);

      classNames = this.props.up && _.concat(classNames, ['up']);
      classNames = this.props.isActive && _.concat(classNames, ['active']);
      classNames = this.props.onClick && _.concat(classNames, ['pointer']);

      return (<TH
        {...props}
        className={classNames}><span>{this.props.children}</span></TH>)
    }
  }

  static Body = class extends Component {
    render () {
      return (<tbody>{this.props.children}</tbody>)
    }
  }

  static Row = class extends Component {
    render () {
      return (<tr onClick={ this.props.onClick  } style={{...styles.row,...this.props.style}}>{this.props.children}</tr>)
    }
  }

  static Cell = class extends Component {
    render () {
      return (<td {...this.props} style={{...styles.cell, ...this.props.style}}>{this.props.children}</td>)
    }
  }
}

export default AxlTable
