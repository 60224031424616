import React, { Component } from 'react';
import moment from 'moment-timezone';
import { ThemeProvider } from 'styled-components';

// Styles
import styles, { Container, Row, Col, Text, Name, SmallText, Logo } from './styles';

class AxlDraftShipmentBox extends Component {
  selectShipment = (e) => {
    const {onSelectShipment} = this.props;
    if (onSelectShipment) onSelectShipment();
  };

    renderMainTheme(shipment) {
      return (
        <Row>
            <Col>
              <div>
                <Name>{ (shipment && shipment.customer) ? shipment.customer.name : '-' }</Name>
              </div>
              <div>
                <Text>{ `${moment(shipment.dropoff_earliest_ts).format('HH:mm A')} - ${moment(shipment.dropoff_latest_ts).format('HH:mm A')}` }</Text>
              </div>
            </Col>
            <Col>
              <div>
                <Text className={`text-right`}>{ (shipment && shipment.dropoff_address) ? shipment.dropoff_address.street : '-' }</Text>
              </div>
              <div>
                <Text className={`text-right`}>{ (shipment && shipment.dropoff_address) ? shipment.dropoff_address.city + ', ' + shipment.dropoff_address.state + ' ' + shipment.dropoff_address.zipcode : '' }</Text>
              </div>
            </Col>
        </Row>
      );
    }

    renderDefaulTheme(shipment) {
      return (
        <Row>
            <Col>
              <div>
                <Text>{ shipment.internal_id }</Text>
              </div>
              <div>
                <Name>{ (shipment && shipment.customer) ? shipment.customer.name : '-' }</Name>
              </div>
            </Col>
            <Col>
              <div>
                <Text className={`text-right`}>{ (shipment && shipment.dropoff_address) ? shipment.dropoff_address.street : '-' }</Text>
              </div>
              <div>
                <Text className={`text-right`}>{ (shipment && shipment.dropoff_address) ? shipment.dropoff_address.city + ', ' + shipment.dropoff_address.state + ' ' + shipment.dropoff_address.zipcode : '' }</Text>
              </div>
            </Col>
        </Row>
      );
    }

    render() {
        const { shipment } = this.props;
        let style = this.props.style ? this.props.style : {};
        const status = this.props.status ? this.props.status: 'GEOCODED';

        return <ThemeProvider theme={{type: this.props.theme ? this.props.theme : 'default'}}>
          <Container
            onClick={this.selectShipment}
            style={{...this.props.style, ...styles.statuses[status.replace(' ', '_')]}}>
            {(this.props.theme && this.props.theme !== 'default') ? this.renderMainTheme(shipment) : this.renderDefaulTheme(shipment)}
        </Container>
      </ThemeProvider>
    }
}

export default AxlDraftShipmentBox
