import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';

// Styles
import styles, * as E from './styles';
import {Error} from "../AxlInput/styles";

class AxlSelect extends Component {
  componentDidMount() {
    console.log('1. ' + this.props.value)
  }

  render () {
    const { options } = this.props;

    return (<ThemeProvider theme={{type: this.props.theme ? this.props.theme : 'default'}}>
      <E.Container>
        <E.Select
          onChange={(a) => { this.props.onSelect && this.props.onSelect(a.target.value) }}
          style={this.props.style} isError={!!this.props.error}
          defaultValue={this.props.value ? this.props.value: options[0].value}>
          {options.map((option, index) => <E.Option key={index} value={option.value} style={styles.option}>{option.name}</E.Option>)}
        </E.Select>
        {this.props.error && this.props.showError && <E.Error>{this.props.error}</E.Error>}
      </E.Container>
    </ThemeProvider>)
  }
}

export default AxlSelect
