import styled from 'styled-components';
import { Colors } from '../../constants/Colors';

const ContainerStyle = {
	default: `
		display: flex;
	`,
	main: `
		display: flex;
	`
}

const InputStyle = {
	default: `
		width: 200px;
		height: 16px;
		border-radius: 3px 0 0 3px;
		border: solid 1px #cfcfcf;
		background-color: #f4f4f4;
		outline: none;
		padding: 7px 20px;
		font-size: 13px;
		font-family: AvenirNext;
		color: #4a4a4a;
	`,
	main: `
		width: 71px;
		height: 16px;
		border-radius: 3px 0 0 3px;
		border: solid 1px ${Colors.veryLightPink};
		background-color: #FFF;
		outline: none;
		padding: 7px 20px;
		font-size: 13px;
		font-family: 'AvenirNext-Medium';
		color: #4a4a4a;
	`
}

const ButtonStyle = {
	default: `
		width: 96px;
		height: 32px;
		border-radius: 0 3px 3px 0;
		background-color: #9da5b6;
		border: none;
		cursor: pointer;
		color: #FFF;
		font-size: 13px;
		font-family: AvenirNext;
		outline: none;
	`,
	main: `
		width: 72px;
		height: 32px;
		border-radius: 0 3px 3px 0;
		background-color: #f4f4f4;
		border: none;
		cursor: pointer;
		color: #848484;
		font-size: 13px;
		font-family: AvenirNext;
		outline: none;
		border: solid 1px ${Colors.veryLightPink};
		border-left: none;
	`
}

export const Container = styled.div`${props => props.theme.type ? ContainerStyle[props.theme.type] : ContainerStyle.default}`;
export const Input = styled.input`${props => props.theme.type ? InputStyle[props.theme.type] : InputStyle.default}`;
export const Button = styled.button`${props => props.theme.type ? ButtonStyle[props.theme.type] : ButtonStyle.default}`;
