import { action, makeObservable } from 'mobx';

class ToastStore {
  constructor(api) {
    makeObservable(this, {
      getList: action,
      getDetail: action,
      update: action
    });

    this.api = api;
  }

  getList = (query) => {
    if(!query) return;

    let params = new URLSearchParams();
    Object.keys(query).map(t => {
      params.append(t, query[t]);
    });

    return this.api.get(`/toasts?${params}`);
  };

  getDetail = (id) => {
    if(!id) return;

    return this.api.get(`/toasts/${id}`);
  };

  update = (id, query) => {
    if(!query) return;

    return this.api.put(`/toasts/${id}`, query);
  };
}

export default ToastStore;
