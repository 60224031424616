import React, { Component } from 'react';
import styles from './styles';

class AxlBox extends Component {
  render () {
    return (<div style={ styles.container }>
        {this.props.children}
    </div>)
  }
  static Row = class extends Component {
    render () {
        return (<div style={ styles.row } className='clearfix'>
            {this.props.children}
        </div>)
      }
  }

  static Section = class extends Component {
    render () {
        return (<div style={ styles.section }>
            {this.props.children}
        </div>)
      }
  }

  static SubSection = class extends Component {
    render () {
        return (<div style={ {...styles.subsection, ...{ width: this.props.fluid ? '100%' : '50%' }} }>
            {this.props.children}
        </div>)
      }
  }

  static Header = class extends Component {
    render () {
        return (<div style={ styles.header }>
            {this.props.children}
        </div>)
      }
  }

  static SubHeader = class extends Component {
    render () {
        return (<div style={ styles.subheader }>
            {this.props.children}
        </div>)
      }
  }

  static Text = class extends Component {
    render () {
        return (<p style={ styles.text }>
            {this.props.children}
        </p>)
      }
  }
}

export default AxlBox
