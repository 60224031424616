import { observable, action, makeObservable } from 'mobx';

class WarehouseStore {

    constructor(api) {
        makeObservable(this, {
            loading: observable,
            warehouses: observable,
            getWarehouses: action
        });

        this.api = api;
    }
    loading = false;

    warehouses = [];

    getWarehouses(regions, cb) {
        this.loading = true;
        this.api.get('/warehouses', {regions})
        .then(response => {
            this.loading = false;
            if (response.status === 200) {
                this.warehouses = response.data;
                if(cb) {
                    cb(response.data);
                }
            }
        })
    }
}

export default WarehouseStore;
