import styled from 'styled-components';
import { rotate } from '../../styled-components/keyframes'

export const Container = styled.div`
  position: relative;
`;

export const Icon = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border-top: 5px solid #CCC;
  border-left: 5px solid #CCC;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  animation: ${rotate} 0.5s linear infinite;
`;
