import styled from 'styled-components';

export const Container = styled.div``;
export const Control = styled.span`
    font-family: 'AvenirNext-DemiBold';
    margin: 2px 0px;
    min-width: 18px;
    padding: 0 10px;
    height: 18px;
    color: #4a4a4a;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
`;

export default {
    pill: {
        fontFamily: 'AvenirNext-DemiBold',
        display: 'inline-block',
        borderRadius: '9px',
        margin: '2px 10px',
        minWidth: '18px',
        padding: '0px 2px',
        height: '18px',
        color: '#4a4a4a',
        fontSize: '10px',
        lineHeight: '18px',
        textAlign: 'center',
        cursor: 'pointer'
    },
    selected: {
        color: 'white',
        backgroundColor: '#887fff'
    },
    icon: {
        fontSize: 11
    }
}
