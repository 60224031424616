import styled from 'styled-components';
import Colors from '../../constants/Colors'

export const Container = styled.div``;

export const Title = styled.div`
  font-family: 'AvenirNext-Medium';
  font-size: 26px;
  font-weight: 500;
  color: #4a4a4a;
  line-height: 1.3em;
  margin: 0 0 10px;
  padding: 0 15px;
  text-align: center;
`;

export const TitleMain = styled(Title)`
  font-family: 'AvenirNext-Bold';
  font-size: 22px;
`;

export const Text = styled.div`
  font-family: 'AvenirNext';
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #bfbfbf;
  line-height: 1.3em;
  padding: 0 15px;
  text-align: center;
`;

export const TextMain = styled(Text)`
  font-family: 'AvenirNext-DemiBold';
  color: #5a5a5a;
`;

export const Control = styled.div`
  margin-top: 30px;
  text-align: center;
`;

export default {
  container: {
    padding: '30px 50px',
    borderRadius: 3,
    borderWidth: 1,
    borderColors: '##cfcfcf'
  },
  buttonControl: {
    minWidth: 120,
    padding: '0 15px'
  },
  buttonControlThird: {
    minWidth: 120,
    padding: '0 5px'
  }
}
