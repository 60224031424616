import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const Inner = styled.div``;
export const Header = styled.div`
  border-bottom: 1px solid ${props => !props.disableRipple ? '#979797;' : 'transparent;'}
`;
export const HeaderInner = styled.div`
  display: flex;
  margin-bottom: -2px;
  justify-content: ${props => props.align || 'left'};
  ${props => props.scroll ? {
    padding: 8,
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      width: 6,
      height: 6,
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#939393',
      borderRadius: 3,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  } : {}};
`;
export const Title = styled.div`
  font-family: AvenirNext-DemiBold;
  // display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: #4a4a4a;
  padding-bottom: 3px;
  margin: 0 10px;
  cursor: pointer;
  min-width: 150px;
  max-width: 100%;
  vertical-align: middle;
  border-bottom: 3px solid ${props => props.active ? '#6c62f5;' : 'transparent;'}
`;
export const Main = styled.div`
  flex: 1;
  height: 100%;
  overflow: hidden;
`;
export const Body = styled.div`
  height: 100%;
  padding-top: 10px;
  box-sizing: border-box;
  display: block;
`;