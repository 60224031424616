const USTimeZones = [
  { 'value': 'America/Adak', 'label': 'Adak' },
  { 'value': 'America/Anchorage', 'label': 'Anchorage' },
  { 'value': 'America/Boise', 'label': 'Boise' },
  { 'value': 'America/Chicago', 'label': 'Chicago' },
  { 'value': 'America/Denver', 'label': 'Denver' },
  { 'value': 'America/Detroit', 'label': 'Detroit' },
  { 'value': 'America/Indiana/Indianapolis', 'label': 'Indiana - Indianapolis' },
  { 'value': 'America/Indiana/Knox', 'label': 'Indiana - Knox' },
  { 'value': 'America/Indiana/Marengo', 'label': 'Indiana - Marengo' },
  { 'value': 'America/Indiana/Petersburg', 'label': 'Indiana - Petersburg' },
  { 'value': 'America/Indiana/Tell_City', 'label': 'Indiana - Tell City' },
  { 'value': 'America/Indiana/Vevay', 'label': 'Indiana - Vevay' },
  { 'value': 'America/Indiana/Vincennes', 'label': 'Indiana - Vincennes' },
  { 'value': 'America/Indiana/Winamac', 'label': 'Indiana - Winamac' },
  { 'value': 'America/Juneau', 'label': 'Juneau' },
  { 'value': 'America/Kentucky/Louisville', 'label': 'Kentucky - Louisville' },
  { 'value': 'America/Kentucky/Monticello', 'label': 'Kentucky - Monticello' },
  { 'value': 'America/Los_Angeles', 'label': 'Los Angeles' },
  { 'value': 'America/Menominee', 'label': 'Menominee' },
  { 'value': 'America/Metlakatla', 'label': 'Metlakatla' },
  { 'value': 'America/New_York', 'label': 'New York' },
  { 'value': 'America/Nome', 'label': 'Nome' },
  { 'value': 'America/North_Dakota/Beulah', 'label': 'North Dakota - Beulah' },
  { 'value': 'America/North_Dakota/Center', 'label': 'North Dakota - Center' },
  { 'value': 'America/North_Dakota/New_Salem', 'label': 'North Dakota - New Salem' },
  { 'value': 'America/Phoenix', 'label': 'Phoenix' },
  { 'value': 'America/Sitka', 'label': 'Sitka' },
  { 'value': 'America/Yakutat', 'label': 'Yakutat' },
  { 'value': 'Pacific/Honolulu', 'label': 'Honolulu' }
];

export default { USTimeZones }
