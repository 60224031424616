import _ from 'lodash';
import { observable, action, makeObservable, computed } from 'mobx';
import moment from 'moment-timezone';

import { GLOBAL_BOOKING_OWNER } from '../constants/common';
import { DEFAULT_DIRECT_BOOKING_COMMUNICATION_TEMPLATE } from '../constants/ticket';

class BookingStore {
  activeSession = null;
  loading = false;
  loadingSessions = false;
  activeSessions = [];
  zones = [];
  regionsStr = '';
  regionPriority = '';
  selectedAssignmentIds = [];
  selectedTicketBookIds = [];
  bookingSessionFormData = { name: '', communication: DEFAULT_DIRECT_BOOKING_COMMUNICATION_TEMPLATE, is_remove_assignment: false };
  bookingLimitSettings = null;

  constructor(logger, api, ws) {
    makeObservable(this, {
      activeSession: observable,
      loading: observable,
      loadingSessions: observable,
      activeSessions: observable,
      zones: observable,
      regionsStr: observable,
      regionPriority: observable,
      selectedAssignmentIds: observable,
      selectedTicketBookIds: observable,
      bookingSessionFormData: observable,
      loadActiveSessions: action,
      loadSession: action,
      reloadSession: action,
      removeAssignmentFromSession: action,
      processBookingSession: action,
      sendPickupTime: action,
      updateMaxReservation: action,
      addDrivers: action,
      refreshSession: action,
      addAssignments: action,
      refreshBook: action,
      sendMessage: action,
      getEstimateSMS: action,
      sendTicketGroupMessage: action,
      getEstimatedTicketGroupMessage: action,
      sendTicketHolderMessage: action,
      addTickets: action,
      refreshAssignments: action,
      refreshBookingAssignments: action,
      getCrews: action,
      searchDriver: action,
      addCrews: action,
      addTicketGroup: action,
      updateTicketGroup: action,
      getZones: action,
      filterBookingByRegionZoneDay: action,
      updatePromotional: action,
      setSelectedAssignments: action,
      setSelectedTicketBooks: action,
      setBookingSessionFormData: action,
      createBookingSession: action,
      cleanupBookingSessionFormState: action,
      sendNotify: action,
      bookingLimitSettings: observable,
      deleteUnBookedTickets: action,
      unbookedTicketsInfo: action,
      discardUnbookedTickets: action,
      globalBookingLimit: computed,
      getGlobalBookingLimit: action,
      updateGlobalBookingLimit: action,
    });

    this.logger = logger;
    this.api = api;
  }

  loadActiveSessions() {
    this.loadingSessions = true;
    this.api.get('/booking/active').then(action((r) => {
        this.activeSessions = this.groupSessions(r.data);
        this.loadingSessions = false;
    }))
  }

  groupSessions(data) {
    let activeSessions = _.sortBy(data, [x => x.target_date]).reverse() || [];
    const today = moment().add(-3, 'hour').startOf('day')
    const past = _.uniq(_.sortBy(activeSessions.map(x => moment(x.target_date)).filter(x => x.isBefore(today)), d => -d.unix())
      .map(m => m.format('dddd MMM DD')))
    const future = _.uniq(_.sortBy(activeSessions.map(x => moment(x.target_date)).filter(x => x.isAfter(today)), d => d.unix())
      .map(m => m.format('dddd MMM DD')))
    const dates = future.concat(past)
    return dates.map(d => Object.assign({date: d}, {
      sessions: data.filter(x => moment(x.target_date).format('dddd MMM DD') === d)
    }))
  }

  loadSession(id, cb) {
    this.loading = true;
    this.api.get(`/booking/${id.split('_')[1]}/detail`).then(action((r) => {
      if (r.ok) {
        this.regionsStr = _.get(r, 'data.session.attributes.regions', '');
        this.regionPriority = this.regionsStr.split(',')[0];
        this.activeSession = this.processBookingSession(r.data);
      } else {
        this.activeSession = null;
        this.regionsStr = ''
      }
      this.getZones()
      this.loading = false;
      if (cb) cb(r);
    }));
  }

  reloadSession(id) {
    if(!this.activeSession || !this.activeSession.session){
      return;
    }

    this.api.get(`/booking/${this.activeSession.session.id}/detail`).then(action((r) => {
      if (r.ok) {
        this.regionsStr = r?.data?.session?.attributes?.regions || '';
        this.activeSession = this.processBookingSession(r.data);
      } else {
        this.activeSession = null;
        this.regionsStr = ''
      }
    }));
  }

  removeAssignmentFromSession(bookId, assignmentId, sessionId, callback) {
    this.loading = true;
    this.api.delete(`/booking/ticket-book/TB_${bookId}/assignment/AS_${assignmentId}`, {session: `BS_${sessionId}`}).then((r) => {
      if (r.ok) {
        console.log('removed assignment', assignmentId, bookId)
      }
      this.loading = false;
      if (callback) callback(r);
    })
  }

  processBookingSession(data) {
    const groupMap = {};
    if (data.session.groups) {
      data.session.groups.forEach((group) => {
        groupMap[group.id] = group;
      });
    }

    const assignmentMap = {};
    if (data.assignments) {
      data.assignments.forEach((assignment) => {
        assignmentMap[assignment.id] = assignment;
      });
    }

    if (data.tickets) {
      data.tickets.forEach((a) => {
        if (a.item && a.item.startsWith('AS_')) {
          let assignment_id = parseInt(a.item.split('_')[1]);
          a.assignment = assignmentMap[assignment_id];
        }
      });
    }

    if (data.books) {
      data.books.forEach((book) => {
        if (data.assignments) {
          const assignment_ids = book.items.map((a) => parseInt(a.split('_')[1]));
          book.assignments = data.assignments.filter((a) => assignment_ids.indexOf(a.id) >= 0);
        }

        const group = groupMap[book.id];
        if (group && data.tickets) {
          const ticket_ids = group.items.map((a) => a.split('_')[1]);
          book.tickets = data.tickets.filter((a) => ticket_ids.indexOf(a.id) >= 0);
        }

        const openAssignments = book?.assignments?.filter((assignment) => !assignment.driver_id) || 0;
        const unclaimed = book?.tickets?.filter((ticket) => ticket.holder && !['CLAIMED', 'COMPLETED'].includes(ticket.status)) || 0;

        book.trueUnbookedCount = Math.max(0, openAssignments.length - unclaimed.length);
      });
    }

    return data;
  }

  sendPickupTime() {
    this.loading = true
    let pickupSession = this.activeSession.session.attributes.pickup_session
    if (pickupSession)
    this.api.post(`/mq/queue/schedule_driver_pickup`, `BS_${pickupSession}`).then((r) => {
      this.loading = false
    })
  }

  updateMaxReservation(limit) {
    this.loading = true
    return this.api.patch(`/booking/${this.activeSession.session.id}`, {max_reservation: limit}).then((r) => {
      if (r.status == 200) {
        this.activeSession.session = r.data
        this.refreshSession()
      }
      this.loading = false
    })
  }

  addDrivers(drivers) {
    this.loading = true
    let driver_ids = drivers.split(/[,\n;]/).filter((s) => s).map((s) => parseInt(s))
    return this.api.post(`/booking/${this.activeSession.session.id}/drivers`, {ids: driver_ids}).then((r) => {
      this.loading = false
      this.loadSession(`BS_${this.activeSession.session.id}`)
    })
  }

  refreshSession() {
    this.loading = true
    this.api.post(`/booking/${this.activeSession.session.id}/refresh`).then((_) => {
      this.loading = false
      this.loadSession(`BS_${this.activeSession.session.id}`)
    })
  }

  addAssignments(bookId, assignments) {
    this.loading = true
    let assignment_ids = assignments.split(/[,\n;]/).filter((s) => s).map((s) => parseInt(s))
    return this.api.post(`/booking/ticket-book/${bookId}?session=${this.activeSession.session.id}`, {ids: assignment_ids}).then((r) => {
      this.loading = false
      this.loadSession(`BS_${this.activeSession.session.id}`)
      return r;
    })
  }

  refreshBook(bookId) {
    this.loading = true
    return this.api.post(`/booking/ticket-book/${bookId}/refresh`).then((r) => {
      this.loading = false
      // this.refreshSession()
    })
  }

  sendMessage(message, toAll, is_promotional) {
    this.loading = true;
    return this.api.post(`/booking/BS_${this.activeSession.session.id}/announce`, message, {
      params: {
        all: toAll,
        is_promotional
      }
    }).then((r) => {
      this.loading = false
    })
  }

  getEstimateSMS(message, toAll, is_promotional) {
    return this.api.post(`/booking/BS_${this.activeSession.session.id}/announce`, message, {
      params: {
        all: toAll,
        is_promotional,
        get_estimated_cost:true
      }
    })
  }


  sendTicketGroupMessage(message, book, is_promotional) {
    this.loading = true
    return this.api.post(`/booking/BS_${this.activeSession.session.id}/announce`, message, {
      params: {
        book,
        is_promotional
      }
    }).then((r) => {
      this.loading = false
    })
  }

  getEstimatedTicketGroupMessage(message, book, is_promotional) {
    return this.api.post(`/booking/BS_${this.activeSession.session.id}/announce`, message, {
      params: {
        book,
        is_promotional,
        get_estimated_cost:true
      }
    })
  }

  sendTicketHolderMessage(message, holder, is_promotional) {
    this.loading = true
    return this.api.post(`/booking/BS_${this.activeSession.session.id}/announce`, message, {
      params: {
        driver: holder,
        is_promotional
      }
    }).then((r) => {
      this.loading = false
    })
  }

  addTickets(noOfTicket, sessionId, bookId) {
    return this.api.put(`/tickets/${sessionId}/tickets`, {
        no_of_ticket_by_group: {
          [bookId]: noOfTicket
        }
    }).then(resp => {
      this.refreshSession();
      return resp;
    });
  }

  refreshAssignments(advanceSessionId) {
    this.loading = true;
    return this.api.post(`/tickets/advance/${advanceSessionId}/assignments`, {}).then(resp => {
      if (resp.ok) {
        this.refreshSession();
      }

      this.loading = false;
      return resp;
    });
  }

  refreshBookingAssignments(sessionId) {
    this.loading = true;
    return this.api.post(`/tickets/booking-sessions/${sessionId}/refresh`, {}).then(resp => {
      if (resp.ok) {
        this.refreshSession();
      }

      this.loading = false;
      return resp;
    });
  }

  getCrews(bsId) {
    return this.api.get(`/booking/${bsId.substr(3)}/crews`);
  }

  searchDriver(bsId, keyword) {
    return this.api.get(`/booking/${bsId}/drivers/search`, {keyword});
  }

  addCrews(bsId, crewIds) {
    return this.api.put(`/tickets/${bsId}/drivers`, {crew_ids: crewIds});
  }

  addTicketGroup(sessionId, payload) {
    this.loading = true;
    return this.api.post(`/tickets/booking-sessions/${sessionId}/add-ticket-group`, {
      groups: [
        payload
      ]
    }).then(resp => {
      this.loading = false;
      return resp;
    });
  }

  updateTicketGroup(sessionId, ticketBookId, payload) {
    this.loading = true;
    return this.api.post(`/booking/${sessionId}/ticket-book/${ticketBookId}`, payload).then(resp => {
      this.loading = false;
      return resp;
    });
  }


  getZones() {
    this.loading = true;
    return this.api.get(`booking/regions/${this.regionsStr}/zones`).then(resp => {
      this.loading = false;
      if(resp.data && (resp.data.code === 404 || resp.data.code === 500)) {
        this.zones = [];
        return;
      }
      this.zones = resp.data
      return resp;
    });
  }

  filterBookingByRegionZoneDay(zoneId, day) {
    if(!this.regionsStr || !zoneId || !day) return [];
    this.loading = true;
    return this.api.get(`booking/regions/${this.regionsStr}/zones/${zoneId}/weekday/${day}`).then(resp => {
      this.loading = false;
      return resp;
    });
  }

  updatePromotional(sessionId, is_promotional) {
    this.loading = true;
    return this.api.post(`booking/${sessionId}/announce`, null, {params: {is_promotional}}).then(resp => {
      this.loading = false;
      return resp;
    });
  }

  setSelectedAssignments(assignmentIds) {
    this.selectedAssignmentIds = assignmentIds;
  }

  setSelectedTicketBooks(bookIds) {
    this.selectedTicketBookIds = bookIds;
  }

  setBookingSessionFormData(field, value) {
    this.bookingSessionFormData[field] = value;
  }

  createBookingSession(data) {
    return this.api.post('driver-schedules/create-from-ticket', data);
  }

  cleanupBookingSessionFormState() {
    this.selectedAssignmentIds = [];
    this.selectedTicketBookIds = [];
    this.bookingSessionFormData = { name: '', communication: DEFAULT_DIRECT_BOOKING_COMMUNICATION_TEMPLATE, is_remove_assignment: false };
  }

  sendNotify(payload, params) {
    return this.api.post(`/booking/BS_${this.activeSession.session.id}/announce`, payload, { params });
  }

  deleteUnBookedTickets(payload) {
    return this.api.post(`/tickets/discard-batch`, payload);
  }

  unbookedTicketsInfo(sessionID) {
    return this.api.get(`/booking/${sessionID}/unbooked-tickets`);
  }

  discardUnbookedTickets(sessionID) {
    return this.api.post(`/booking/${sessionID}/discard-unbooked-tickets`);
  }

  get globalBookingLimit() {
    if (!this.activeSession) return null;

    const targetDate = _.get(this.activeSession, 'session.target_date');
    const timezone = _.get(this.activeSession, 'session.timezone');
    const dayOfWeek = targetDate ? moment(targetDate).tz(timezone).day() : null;
    const targetDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;

    return _.get(this.bookingLimitSettings, targetDayOfWeek);
  }

  getGlobalBookingLimit(owners) {
    this.api.get(`/metadata/BOOKING/key/booking_limit`, { owners }).then((response) => {
      if (response.status !== 200) return;

      this.bookingLimitSettings = response.data;
    });
  }

  updateGlobalBookingLimit(limit) {
    const targetDate = _.get(this.activeSession, 'session.target_date');
    const timezone = _.get(this.activeSession, 'session.timezone');
    const dayOfWeek = targetDate ? moment(targetDate).tz(timezone).day() : null;
    const targetDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;

    const settings = _.clone(this.bookingLimitSettings);
    settings[targetDayOfWeek] = limit;

    if (!limit) delete settings[targetDayOfWeek];

    const region = `RG_${this.regionPriority}`;
    const data = { type: 'java.util.Map', value: JSON.stringify(settings) };

    return this.api.put(`/metadata/${region}/BOOKING/booking_limit`, data).then((response) => {
      if (!response.ok) return Promise.reject(response?.data?.message || 'Something went wrong!');

      return this.getGlobalBookingLimit([region, GLOBAL_BOOKING_OWNER]);
    });
  }
}

export default BookingStore
