import { action, makeObservable } from 'mobx';

class PaymentStore {
  constructor(api) {
    makeObservable(this, {
      loadPaystub: action
    });

    this.api = api;
  }

  loadPaystub(paymentId) {
    return this.api.get(`/driver-payments/${paymentId}/summary`);
  }
}

export default PaymentStore;
