import { observable, action, makeObservable } from 'mobx';

class RequestErrorHandler {
  constructor() {
    makeObservable(this, {
      errors: observable,
      error: action,
      clearErrors: action
    });
  }

  errors = [];

  error = (data) => {
    this.errors.push(data);
  };

  clearErrors = () => {
    this.errors = [];
  };
}

export default RequestErrorHandler;
