import styled from 'styled-components';
import { STATUS_COLOR_CODE, Colors } from '../../constants/Colors';

export const ContainerStyle = {
    default: `
        background-color: white;
        box-sizing: border-box;
        border-radius: 3px;
        padding: 10px 15px;
        line-height: 1.5em;
        font-size: 15px;
        box-shadow: 0px 0px 1px #888;
        min-height: 44px;
        margin: 10px 1px;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        text-align: left;
        border-right: solid 6px;
        border-right-color: ${STATUS_COLOR_CODE.PENDING};
    `,
    main: `
        background-color: white;
        box-sizing: border-box;
        border-radius: 3px;
        padding: 10px 15px;
        line-height: 1.5em;
        font-size: 15px;
        min-height: 44px;
        margin: 10px 1px;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        text-align: left;
        border: 1px solid ${Colors.veryLightPink};
    `
};

export const RowStyle = {
    default: `
        display: flex;
        flex-direction: row;
    `,
    main: `
        display: flex;
        flex-direction: row;
    `
};

export const ColStyle = {
    default: `
        flex: 1;
    `,
    main: `
        flex: 1;
    `
};

export const LogoStyle = {
    default: `
        position: absolute;
        top: 14px;
        left: 4px;
        width: 32px;
        height: 32px;
    `,
    main: ``
};

export const TextStyle = {
    default: `
        font-family: 'AvenirNext-Medium';
        font-size: 13px;
        font-weight: 500;
        line-height: 17px;
        text-align: left;
        color: #3b3b3b;
        margin-bottom: 5px;

        &.text-right { text-align: right; }
        &.text-center { text-align: center; }
        &.text-left { text-align: left; }
    `,
    main: `
        font-family: 'AvenirNext-Medium';
        font-size: 13px;
        font-weight: 500;
        line-height: 17px;
        text-align: left;
        color: #3b3b3b;
        margin-bottom: 5px;

        &.text-right { text-align: right; }
        &.text-center { text-align: center; }
        &.text-left { text-align: left; }
    `
};

export const SmallTextStyle = {
    default: `
      font-family: 'AvenirNext-Medium';
      font-size: 11px;
      font-weight: 300;
      color: #55a;
      display: 'inline-block';
    `,
    main: `
      font-family: 'AvenirNext-Medium';
      font-size: 11px;
      font-weight: 300;
      color: #55a;
      display: 'inline-block';
    `
};

export const NameStyle = {
    default: `
        font-family: 'AvenirNext-Bold';
        font-size: 14px;
        font-weight: bold;
        color: #3b3b3b;
        margin-bottom: 5px;
    `,
    main: `
        font-family: 'AvenirNext-Bold';
        font-size: 14px;
        font-weight: bold;
        color: #3b3b3b;
        margin-bottom: 5px;
    `
};

export const Container = styled.div`${props => props.theme.type ? ContainerStyle[props.theme.type] : ContainerStyle.default}`;
export const Row = styled.div`${props => props.theme.type ? RowStyle[props.theme.type] : RowStyle.default}`;
export const Col = styled.div`${props => props.theme.type ? ColStyle[props.theme.type] : ColStyle.default}`;
export const Logo = styled.div`${props => props.theme.type ? LogoStyle[props.theme.type] : LogoStyle.default}`;
export const Text = styled.div`${props => props.theme.type ? TextStyle[props.theme.type] : TextStyle.default}`;
export const SmallText = styled.span`${props => props.theme.type ? SmallTextStyle[props.theme.type] : SmallTextStyle.default}`;
export const Name = styled.div`${props => props.theme.type ? NameStyle[props.theme.type] : NameStyle.default}`;

export default {
    statuses: {
        GEOCODED: {
            borderColor: Colors.veryLightPink
        },
        GEOCODE_FAILED: {
            borderColor: Colors.dustyRed
        },
        GEOCODE_FAILED_DROPOFF: {
            borderColor: Colors.dustyRed
        },
        GEOCODE_FAILED_PICKUP: {
            borderColor: Colors.dustyRed
        },
        UNSERVICEABLE: {
            borderColor: Colors.dustyRed
        }
    }
}
