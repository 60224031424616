import React, { Component } from 'react';
import styles, { MenuItem, DropDownMenuContainer, DropDownContainer, LabelContainer } from './styles';

class AxlSimpleDropDown extends Component {
  constructor(props) {
    super(props);
    const { anchor } = props
    const topStyle = anchor && anchor.indexOf('top') >= 0 ? styles.dropdown_anchor.top : {}
    const bottomStyle = anchor && anchor.indexOf('bottom') >= 0 ? styles.dropdown_anchor.bottom : {}
    const leftStyle = anchor && anchor.indexOf('left') >= 0 ? styles.dropdown_anchor.left : {}
    const rightStyle = anchor && anchor.indexOf('right') >= 0 ? styles.dropdown_anchor.right : {}
    this.state = {
      open: false,
      topStyle,
      bottomStyle,
      leftStyle,
      rightStyle
    }
    this.onToggle = this.onToggle.bind(this)
  }

  onSelect(m) {
    this.setState({open: false})
    m.action && m.action(m)
  }

  onToggle(e) {
    this.setState({open: !this.state.open})
    e.stopPropagation()
  }

  componentDidMount() {
    this.handleClick = window.addEventListener("click", () => this.setState({open: false}));
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleClick)
  }

  render() {
    const {disabled = false} = this.props;

    return <DropDownContainer>
      <LabelContainer
        disabled={disabled}
        onClick={ !disabled ? this.onToggle : () => {} }>
        { this.props.children }
      </LabelContainer>
      { this.state.open && <DropDownMenuContainer
        style={{ ...this.state.topStyle, ...this.state.bottomStyle, ...this.state.leftStyle, ...this.state.rightStyle, ...this.props.style}}>
      { this.props.items.map((item, i) => <MenuItem key={i} onClick = {() => this.onSelect(item)}>{item.title}</MenuItem>)}
      </DropDownMenuContainer> }
    </DropDownContainer>
  }
}

export default AxlSimpleDropDown
