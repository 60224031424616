import { action, makeObservable } from 'mobx';
import _ from 'lodash';
import moment from "moment";

class MessengerInStore {
  constructor(api) {
    makeObservable(this, {
      getTopic: action,
      getMessages: action,
      getAssignmentConversationSummary: action,
      getDriverByIds: action,
      getActivityLogs: action,
      getAssignmentEvent: action,
      getAssignmentDetail: action
    });

    this.api = api;
  }

  getTopic = () => this.api.get(`/messenger/topics?ref_id=185775&ref_type=ASSIGNMENT_CONVERSATION`);

  getMessages = (id, query = {}) => {
    if(!id) return;
    let params = new URLSearchParams();

    Object.keys(query).map(t => {
      params.append(t, query[t]);
    });

    return this.api.get(`/messenger/topics/${id}/messages?${params}`);
  };

  getAssignmentConversationSummary = (
    query = {
      driver_id: 343,
      start_ts: moment().startOf('day').unix() * 1000,
      end_ts: moment().add(1).endOf('day').unix() * 1000,
    }) => {
    let params = new URLSearchParams();

    Object.keys(query).map(t => {
      params.append(t, query[t]);
    });

    return this.api.get(`/messenger/assignment_with_conversations?${params}`);
  };

  getDriverByIds(ids = []) {
    if(!ids.length) return;

    return this.api.post(`/users/drivers`, ids);
  }

  getActivityLogs(topicId) {
    if(!topicId) return;

    const query = {
      topic_id: topicId
    };
    let params = new URLSearchParams();
    Object.keys(query).map(t => {
      params.append(t, query[t]);
    });

    return this.api.get(`/messenger/activity_logs`, params);
  }

  getAssignmentEvent(id) {
    if(!id) return;

    return this.api.get(`/events/assignments/${id}`);
  }

  getAssignmentDetail(id) {
    if(!id) return;

    return this.api.get(`/assignments/${id}/detail?show_soft_deleted=true`);
  }
}

export default MessengerInStore;
