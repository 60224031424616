import React, { Component } from 'react';
import styles, { Container, Inner, ContainerTag, Row, Col, ProgressBar, Label, Zoner, Driver, Counter, Failer, Ribbon, Text, SmallText, Panel, MoreLink, ETA } from './styles';
import { region_name } from '../../constants/Regions';
import moment from 'moment-timezone';
import _ from 'lodash';

// Component
import AxlTags from '../AxlTags';
import {DayText, TimeZoneText} from "../AxlMiniStopBox/styles";

export class ZoneList extends Component {
  constructor (props) {
    super (props)
    this.state = {
      openMore: false
    }
  }
  render () {
    const { items } = this.props

    return <div>
        {!this.state.openMore && items[0]}
        {items.length > 1 && <span>{this.state.openMore ? items.join(' / ') : <MoreLink onClick={() => this.setState({openMore: true})}>{` +1 more`}</MoreLink>}</span>}
    </div>
  }
}

class ShowETA extends Component {
  constructor(props) {
    super(props)
    const {eta} = this.props
    const e = moment(eta)
    this.state = {
      late: e.isBefore(moment.now()),
      veryLate: e.isBefore(moment().add(-30, 'minutes')),
    }
    this.refresh = this.refresh.bind(this)
  }
  componentDidUpdate() {
    this.refresh();
  }
  refresh() {
    const {eta} = this.props
    const e = moment(eta)
    if (this.state.veryLate) {
      if (!e.isBefore(moment().add(-30, 'minutes')))
      this.setState({
        veryLate: false
      })
    }
    else if (e.isBefore(moment().add(-30, 'minutes')))
      this.setState({
        veryLate: true
      })

    if (this.state.late) {
      if (!e.isBefore(moment()))
      this.setState({
        late: false
      })
    }
    else if (e.isBefore(moment()))
      this.setState({
        late: true
      })
  }
  componentDidMount() {
    this.refresh()
    this.interval = setInterval(this.refresh, 30000)
  }
  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval)
  }

  render() {
    const {eta} = this.props
    const color = this.state.veryLate ? "#f22" : this.state.late ? "#c84" : "#666";
    return <Text><ETA style={{backgroundColor: color}}><span style={{fontSize: '0.9em', color: '#eee'}}>ETA </span>{moment.tz(eta, moment.tz.guess()).format("hh:mma z")}</ETA></Text>
  }
}

class AxlMiniAssignmentBox extends Component {
    constructor(props) {
        super(props)
    }
    render() {
      const { assignment, eta, badge, driverTags } = this.props;
      const ss = ['PS', 'DF', 'DS', 'DP'];
      let dropoffStatusArray = [];
      if (assignment.dropoffStatusArray) {
        dropoffStatusArray = assignment.dropoffStatusArray
      } else if (assignment.extra && assignment.extra.dropoff_status) {
        const ss = _.split(a.extra.dropoff_status, '|').filter(s => s && s[0] === 'D');
        let lastS = {status: '', count: 0}
        for (let s of ss) {
          if (s === lastS.status) {
            lastS.count += 1
          } else {
            lastS = {status: s, count: 1}
            dropoffStatusArray.push(lastS)
          }
        }
      }

      if (assignment.minified)
        return <Container
          onClick={ () => this.props.onClick && this.props.onClick()}
          style={{...this.props.style}} ></Container>
      const driver = assignment.driver ? `${assignment.driver.first_name} ${assignment.driver.last_name}` : '-';
      const label = assignment.label ? (assignment.label.preifx ? assignment.label.prefix : assignment.label) : '-';
      const zones = assignment.zones ? region_name(assignment.zones) : region_name(assignment.region_code);
      const counter = assignment.shipment_count ? assignment.shipment_count : '-';
      const completed = assignment.completed ? assignment.completed : '0'
      const failure_counter = assignment.failed ? assignment.failed : 0;
      const completed_counter = assignment.extra && assignment.extra.dropoff_status ? assignment.extra.dropoff_status.split('|').filter(a => a === 'DF' || a === 'DS' || a === 'DL' || a === 'DE').length : '-'
      const status = this.props.status ? this.props.status.replace(' ', '_') : 'UNASSIGNED';
      const onDemand = assignment.logistic_type === 'ON_DEMAND';
      const ts = assignment.is_active && assignment.actual_start_ts ? assignment.actual_start_ts : assignment.predicted_start_ts;

      const cardStyles = this.props.cardStyles || {};
      // const tags = [
      //   {
      //     text: 'Commercial'
      //   },
      //   {
      //     text: 'VIP'
      //   },
      //   {
      //     text: 'Flagged'
      //   }
      // ];

      // Timezone
      let t1 = '', t2 = '', tz2 = '', t3 = '', t4 = '', tz4 = '', showDiffDay = '';
      const localTimezone     = moment.tz.guess();
      const defaultTimezone   = _.has(assignment, 'timezone') && !_.isEmpty(_.has(assignment, 'timezone')) ? _.get(assignment, 'timezone') : 'America/Los_Angeles';
      const isSameTimezone    = moment.tz(defaultTimezone).utcOffset() === moment.tz(localTimezone).utcOffset();

      if (assignment && _.has(assignment, 'predicted_start_ts') && _.has(assignment, 'predicted_latest_start_ts')) {
        const isDiffDay   = moment.tz(assignment.predicted_start_ts, defaultTimezone).diff(moment.tz(localTimezone), 'days');
              showDiffDay = isDiffDay !== 0 ? `${isDiffDay > 0 ? ' +' : ''} ${isDiffDay}day${Math.abs(isDiffDay) > 1 ? 's' : ''}` : '';
              t1          = moment.tz(assignment.predicted_start_ts, localTimezone).format('h:mma'),
              t2          = moment.tz(assignment.predicted_latest_start_ts, localTimezone).format('h:mma'),
              tz2         = moment.tz(assignment.predicted_latest_start_ts, localTimezone).format('z'),
              t3          = moment.tz(assignment.predicted_start_ts, defaultTimezone).format('h:mma'),
              t4          = moment.tz(assignment.predicted_latest_start_ts, defaultTimezone).format('h:mm a'),
              tz4         = moment.tz(assignment.predicted_latest_start_ts, defaultTimezone).format('z');
      }

      return <Container
        onClick={ () => this.props.onClick && this.props.onClick()}
        style={{...this.props.style}}
      >
        {badge && <Ribbon>{badge}</Ribbon>}
        <Inner style={{backgroundColor: cardStyles.backgroundColor, color: cardStyles.color}}>
          <ProgressBar style={{...styles.statuses[status]}}>
            {dropoffStatusArray.map((e, i) => <Panel style={{...styles.PanelStatus[e.status], ...{flex: e.count}}} key={i} />)}
          </ProgressBar>
          <Row>
            <Col style={{flex: 0.6}}>
              <div>
                <Text>
                  <span>{ label }&nbsp;</span>
                  <SmallText>
                    [{t1} - {t2} <TimeZoneText>{tz2}</TimeZoneText>]
                    {!isSameTimezone ? <span>[{t3} - {t4} <TimeZoneText>{tz4}</TimeZoneText>]</span> : ''}
                    <DayText>{showDiffDay}</DayText>
                  </SmallText>
                </Text>
              </div>
            </Col>
            <Col style={{flex: 0.4}}>
              <Text className={`text-right`}><ZoneList items={zones} /></Text>
            </Col>
          </Row>
          <Row>
            <Col style={{flex: 2, display: 'flex'}}>
              <Text>{ driver }</Text>
              {driverTags && driverTags.map(driverTag => <Text style={{transform: 'skew(-20deg)', background: '#a2d55c', padding: '0px 10px', color: '#fff', marginLeft:10, marginBottom:9}}>
                                                          <Text style={{transform: 'skew(20deg)', color:'#fff', marginBottom: 0, fontSize:10}}>{ driverTag }</Text>
                                                        </Text>)}
            </Col>
            { eta && <Col style={{width: 90}}><ShowETA eta={eta.eta} /></Col> }
            <Col style={{width: 60}}>
              <Row>
                <Col>{ (failure_counter > 0) && <Text className={`text-right`}><Failer>{failure_counter}</Failer></Text> }</Col>
                <Col><Text className={`text-right`}>{ completed_counter }/{ counter }</Text></Col>
              </Row>
            </Col>
          </Row>
        </Inner>
        {this.props.tags && <ContainerTag>
          <AxlTags isShowLabel={this.props.isShowLabelTag} tags={this.props.tags} message={this.props.message} />
        </ContainerTag>}
    </Container>
    }
}

export default AxlMiniAssignmentBox
