import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';
import styles, { Container, Close, Inner, ModalContent, ModalBg } from './styles';

class AxlModal extends Component {
  render() {
    return <ThemeProvider theme={{
        type: this.props.theme ? this.props.theme : 'default'
      }}>
      <Container style={{...this.props.containerStyle}}>
        <Inner>
          {!this.props.hideClose && <Close onClick={this.props.onClose}>{`x`}</Close>}
          <ModalContent style={{...this.props.style}}>
              { this.props.children }
          </ModalContent>
        </Inner>
        <ModalBg onClick={this.props.onClose} />
      </Container>
    </ThemeProvider>
  }
}

export default AxlModal;
