import { action, makeObservable } from 'mobx';

class DriverRatingConfig {
  constructor(api) {
    makeObservable(this, {
      listByDriverType: action,
      getColorConfigRating: action,
      reloadCachedByDriverID: action
    });

    this.api = api;
  }

  listByDriverType(driver_type) {
    return this.api.get(`/driver-rating/config`, {driver_type})
  }

  getColorConfigRating() {
    return this.api.get(`/driver-rating/color-config`)
  }

  reloadCachedByDriverID = (driverID) => {
    if(!driverID) return;

    return this.api.get(`/driver-rating/${driverID}/performance`, {bypass_cache: true});
  };
}

export default DriverRatingConfig;
