import React, { Component } from 'react';
//Styles
import styles, * as E from './styles';
// Components
import ImageLink from '../../images/svg/image-link.svg';
import AxlLoading from "../AxlLoading";

export default class AxlChatForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyText: '',
      previewImages: [],
      uploading: false
    };
    this.imgRef = React.createRef();
    this._handlePickerImage = this._handlePickerImage.bind(this);
  }

  componentDidMount() {
    this.setState({uploading: this.props.uploading});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.uploading !== this.props.uploading) {
      this.setState({uploading: this.props.uploading});
    }
  }

  _handlePickerImage() {
    this.imgRef.current.click();
  }

  _handleSelectedImage = (e) => {
    if(e.target.files) {
      const files = Array.from(e.target.files);
      Promise.all(files.map(file => {
        return (new Promise((resolve,reject) => {
          const reader = new FileReader();
          reader.addEventListener('load', (ev) => {
            resolve(ev.target.result);
          });
          reader.addEventListener('error', reject);
          reader.readAsDataURL(file);
        }));
      })).then(images => {
        this.setState({
          previewImages: images
        });
        var newFiles = files.map(file => {
          var blob = file.slice(0, file.size, file.type);
          var newFileName = file.name.replace(/\s/g, '');
          var newFile = new File([blob], newFileName, {type: file.type});
          return newFile;
        });
        this.props.handleUploadFile(newFiles);
      })
    }
  };

  _removeImagePreview = (i) => {
    let newPreviewImages = this.state.previewImages;
    newPreviewImages.splice(i, 1);
    this.setState({
      previewImages: newPreviewImages
    });
    this.props.handleRemoveUploadFile(i);
  };

  _handleChangeText = (e) => {
    // if(e.target.value === '') return false;
    this.setState({bodyText: e.target.value});
  };

  _handleSubmitForm = () => {
    const { handleSubmit } = this.props;

    if(!this.state.bodyText && !this.state.previewImages.length) return false;

    handleSubmit(this.state.bodyText);
    //Clear value after sending text
    this.setState({bodyText: '', previewImages: []})
  };

  render() {
    const {handleSendLink} = this.props;
    const { previewImages, uploading } = this.state;

    return <E.Container>
      {/*<E.TypingContainer>*/}
      {/*  {<E.TypingText>{`Dispatcher Katie is typing…`}</E.TypingText>}*/}
      {/*</E.TypingContainer>*/}
      {!!previewImages.length && <E.ImageContainer>
        {previewImages.map((src, index) => <E.ImagePreviews key={index}>
          <E.CloseImage onClick={() => this._removeImagePreview(index)}>x</E.CloseImage>
          <E.ImagePreview src={src} />
          {this.state.uploading && <E.LoadingContainer><AxlLoading thin={1} color={`#000`} size={20} /></E.LoadingContainer>}
        </E.ImagePreviews>)}
      </E.ImageContainer>}
      <E.FormChat>
        <E.ImagePicker type={`image`} src={`/assets/images/image-picker.png`} onClick={this._handlePickerImage} />
        {handleSendLink && <E.ImageLink type={`image`} src={ImageLink} onClick={handleSendLink} />}
        <input type={`file`} multiple accept="image/jpg, image/png" ref={this.imgRef} style={{display: 'none'}} onChange={this._handleSelectedImage} />
        <E.TextInput
          value={this.state.bodyText}
          type={`text`} placeholder={`Type your message here …`}
          onKeyPress={event => event.key === 'Enter' && this._handleSubmitForm()}
          onChange={this._handleChangeText} />
        <E.SubmitButton
          disabled={this.state.uploading}
          onClick={() => this._handleSubmitForm()}>{`SEND`}</E.SubmitButton>
      </E.FormChat>
    </E.Container>;
  }
}