import _ from 'lodash';
import { observable, action, runInAction, makeObservable } from 'mobx';

import api from './api';
import { REQUEST_STATUS_IDLE, REQUEST_STATUS_LOADING, REQUEST_STATUS_LOADED, isDeniedAction } from 'constants/common';

class Permission {
  deniedPatterns = [];
  configLoaded = REQUEST_STATUS_IDLE;

  constructor() {
    makeObservable(this, {
      configLoaded: observable,
      deniedPatterns: observable,
      getPermissionSetting: action,
    });

    this.configLoaded = REQUEST_STATUS_IDLE;
    this.deniedPatterns = [];
  }

  async getPermissionSetting() {
    if (this.configLoaded === REQUEST_STATUS_LOADING) return;

    this.configLoaded = REQUEST_STATUS_LOADING;

    await api.get('acl/setting/denied').then((response) => {
      runInAction(() => {
        this.configLoaded = REQUEST_STATUS_LOADED;
        if (response.ok) this.deniedPatterns = response.data.map((item) => ({ method: item.http_method, path: item.path }));
      });
    });
  }

  isDenied(actionPatterns) {
    if (_.isEmpty(actionPatterns) || _.isEmpty(this.deniedPatterns)) return false;

    return isDeniedAction(actionPatterns, this.deniedPatterns);
  }
}

export default Permission;
