import { observable, action, makeObservable } from 'mobx';

class LookAndFeelStore {
  fullscreen = false;

  isHideMainMenu = false;

  currentPath = null;

  constructor() {
    makeObservable(this, {
      fullscreen: observable,
      isHideMainMenu: observable,
      currentPath: observable,
      toggleFullScreen: action,
      setCurrentPath: action,
      toggleMainMenu: action
    });
  }

  toggleFullScreen() {
    this.fullscreen = !this.fullscreen;
  }

  setCurrentPath(p) {
    this.currentPath = p
  }

  toggleMainMenu() {
    this.isHideMainMenu = !this.isHideMainMenu;
  }
}

export default LookAndFeelStore;
