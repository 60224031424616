import styled from 'styled-components';
import Flatpickr from 'react-flatpickr';

const AxlFlatpickrStyle = {
  default: `
    display: block;
    height: 0;
    width: 0;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
  `,
  main: `
    display: block;
    height: 0;
    width: 0;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
  `
}

const ContainerStyle = {
  default: `
    display: inline-block;
    margin: 0 10px;
  `,
  main: `
    display: block;
  `
}

const InputStyle = {
  default: `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 145px;
    border-bottom: 1px solid #887fff;
  `,
  main: `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 38px;
    padding: 10px 15px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 3px;
  `
}

const IconStyle = {
  default: `
    width: 0px;
    height: 0px;
    display: inline-block;
    border-top: 5px solid #bebfc0;
    border-left: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid transparent;
  `,
  main: `
    width: 0px;
    height: 0px;
    display: inline-block;
    border-top: 5px solid #bebfc0;
    border-left: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid transparent;
  `
};

const TextStyle = {
  default: `
    font-family: 'AvenirNext-Bold';
    font-size: 18px;
    font-weight: bold;
    color: #887fff;
    display: flex;
    flex: 1;
  `,
  main: `
    font-family: 'AvenirNext';
    font-size: 14px;
    color: #bfbfbf;
    display: flex;
    flex: 1;
  `
}

export const AxlFlatpickr = styled(Flatpickr)`${props => props.theme.type ? AxlFlatpickrStyle[props.theme.type] : AxlFlatpickrStyle.default}`;

export const Container = styled.div`${props => props.theme.type ? ContainerStyle[props.theme.type] : ContainerStyle.default}`;

export const Input = styled.div`${props => props.theme.type ? InputStyle[props.theme.type] : InputStyle.default}`;

export const Icon = styled.div`${props => props.theme.type ? IconStyle[props.theme.type] : IconStyle.default}`;

export const Text = styled.div`${props => props.theme.type ? TextStyle[props.theme.type] : TextStyle.default}`;
