import styled from 'styled-components';

const ContainerStyle = {
	default: `
		margin: 0 5px 0
	`,
	gray: ``,
  main: ``
}

const SelectStyle = {
	default: `
		padding: 2px 20px 5px 12px;
		min-width: 140px;
		height: 35px;
		border-radius: 5px;
		background-color: #FFF;
		font-size: 10px;
		font-family: 'AvenirNext-DemiBold';
		color: #96979a;
		outline: none;
		line-height: 2em;
		border: 1px solid #cfcfcf;
	`,
	gray: `
		width: 100%;
    height: 46px;
    font-size: 13px;
    line-height: 36px;
    box-sizing: border-box;
    padding: 4px 14px 4px 8px;
    outline: none;
    border-radius: 4px;
    box-shadow: 2px 2px 6px 1px #00000025;
    border: solid 1px #aeaeae;
    background-color: #cccccc1d;
    font-family: 'AvenirNext-Medium';
    font-weight: 500;
    color: #8d8d8d;
	`,
  main: `
    background-color: #f9f9f9;
    height: 39px;
    font-size: 17px;
    line-height: 36px;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 4px 14px 4px 8px;
    border: solid 1px #ccc;
    outline: none;
    width: 100%;
  `,
  tiny: `
    border-radius: 3px;
    border: solid 1px #e4e4e4;
    background-color: #f9f9f9;
    padding: 3px;
  `,
}

const OptionStyle = {
	default: `
		line-height: 2em;
		padding: 10px;
	`,
	gray: `
		line-height: 2em;
		padding: 10px;
	`,
  main: `
    line-height: 2em;
    padding: 10px;
  `,
  tiny: `
    line-height: 2em;
    padding: 10px;
  `
}

const ErrorStyle = {
  default: `
    color: red;
    margin: 5px 0 0;
    font-size: 11px;
  `,
  main: ``,
  periwinkle: ``,
  gray: `
    color: #d0021b;
    margin: 5px 0 0;
    font-size: 11px;
    margin-bottom: 3px;
  `,
  tiny: `
    color: #d0021b;
    margin: 5px 0 0;
    font-size: 11px;
    margin-bottom: 3px;
  `
};

const SelectErrorStyle = {
  default: `
    border: solid 1px red;
    outline: none;
  `,
  main: `
    border: solid 1px red;
    outline: none;
  `,
  periwinkle: ``,
  gray: `
    border: solid 1px #d0021b;
    outline: none;
  `,
  tiny: `
    border: solid 1px #d0021b;
    outline: none;
  `
};

export const Container = styled.div`${props => props.theme.type ? ContainerStyle[props.theme.type] : ContainerStyle.default}`;
export const Select = styled.select`
  ${props => props.theme.type ? SelectStyle[props.theme.type] : SelectStyle.default}
  ${props => props.isError ? props.theme.type ? SelectErrorStyle[props.theme.type] : SelectErrorStyle.default : {}}
`;
export const Option = styled.option`${props => props.theme.type ? OptionStyle[props.theme.type] : OptionStyle.default}`;
export const Error = styled.p`${props => props.theme.type ? ErrorStyle[props.theme.type] : ErrorStyle.default}`;

export default {}
