import React, { Component } from 'react';

import styles, { Container, Icon } from './styles';

export default class AxlLoading extends Component {
  render() {
    const { color, size, thin, style } = this.props
    const loadingDefaultStyle = {
      borderWidth: thin ? thin : '5px',
      borderTopColor: color ? this.props.color : '#CCC',
      borderLeftColor: color ? this.props.color : '#CCC',
      width: size ? size : '35px',
      height: size ? size : '35px'
    }
    return <Icon style={{...loadingDefaultStyle, ...style}}/>
  }
}
