import React, { Component } from 'react';
import styles, { Container, Control } from './styles';

class AxlPagination extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pages: this.processPages(this.props.current, this.props.total, this.props.span ? this.props.span : 3)
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({pages: this.processPages(nextProps.current, nextProps.total, nextProps.span ? nextProps.span : 3)})
    }

    processPages(current, total, span) {
        var pages = [];
        var i;
        for (i = 0; i < span && i < total; i++) {
            pages.push(i + 1);
        }
        if (current - span - 1 > span) {
            pages.push(-1);
        }
        for (i = Math.max(0, current - span); i < current + span - 1 && i < total; i++) {
            if (pages.indexOf(i + 1) < 0) {
                pages.push(i + 1);
            }
        }
        if (current + 2 * span < total) {
            pages.push(-1)
        }
        for (i = Math.max(0, total - span); i < total; i++) {
            if (pages.indexOf(i + 1) < 0) {
                pages.push(i + 1);
            }
        }
        return pages;
    }

    nextPage = () => {
        this.props.onSelect && this.props.onSelect(this.props.current + 1)
    }

    prevPage = () => {
        this.props.onSelect && this.props.onSelect(this.props.current - 1)
    }

    render() {
        return (
            <Container style={this.props.containerStyle}>
                {(this.props.controls && this.props.current > 1) && <Control onClick={this.prevPage}><i className="fa fa-angle-left" style={styles.icon} /></Control>}
                {this.state.pages.map((i,j) => i < 0 ? <span key={j}>...</span> : <span onClick = {() => {this.props.onSelect && this.props.onSelect(i)}} style={{...styles.pill, ...(i === this.props.current && styles.selected)}} key={j}>{i}</span>)}
                {(this.props.controls && this.props.current < this.props.total) && <Control onClick={this.nextPage}><i className="fa fa-angle-right" style={styles.icon} /></Control>}
            </Container>
        )
    }
}

export default AxlPagination
