import styled from 'styled-components';

const ContainerStyle = {
  default: `
    height: 100%;
    padding: 0 30px;
    overflow-y: scroll;
  `,
  main: `
    margin: 15px 0;
  `
}

const FormStyle = {
  default: `
    margin-bottom: 10px;
  `,
  main: `
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;
  `
}

const ImagePreviewStyle = {
  default: `
    width: 100%;
    height: calc(100% - 30px);
  `,
  main: `
    margin: 0 18px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `
}

const ImageStyle = {
  default: `
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  `,
  main: `
    width: 100%;
    max-width: 100px;
    height: auto;
  `
}

const PreviewTextStyle = {
  default: ``,
  main: ``
}

const InnerStyle = {
  default: `
    padding: 0 10px;
    margin: 0 0 15px;
  `,
  main: `
    display: flex;
    align-items: center;
  `
}

const InputFileStyle = {
  default: `
    margin-bottom: 15px;
    outline: none;
  `,
  main: ``
}

const TitleStyle = {
  default: `
    font-size: 20px;
    text-align: center;
    margin: 0 0 30px;
  `,
  main: `
    display: none;
  `
}

export const Container = styled.div`${props => props.theme.type ? ContainerStyle[props.theme.type] : ContainerStyle.default}`;
export const Form = styled.form`${props => props.theme.type ? FormStyle[props.theme.type] : FormStyle.default}`;
export const ImagePreview = styled.div`${props => props.theme.type ? ImagePreviewStyle[props.theme.type] : ImagePreviewStyle.default}`;
export const Image = styled.img`${props => props.theme.type ? ImageStyle[props.theme.type] : ImageStyle.default}`;
export const PreviewText = styled.div`${props => props.theme.type ? PreviewTextStyle[props.theme.type] : PreviewTextStyle.default}`;
export const Inner = styled.div`${props => props.theme.type ? InnerStyle[props.theme.type] : InnerStyle.default}`;
export const InputFile = styled.input.attrs(() => ({type: 'file'}))`${props => props.theme.type ? InputFileStyle[props.theme.type] : InputFileStyle.default}`;
export const Title = styled.div`${props => props.theme.type ? TitleStyle[props.theme.type] : TitleStyle.default}`;
