import styled from 'styled-components';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import AxlFlex from '../AxlFlex';
import Media from '../../styled-components/media'

export const Container = styled(AxlFlex)`
  padding-left: 15px;
  padding-right: 15px;
`;

export const Row = styled(AxlFlex.Row)`
  align-items: center;
`;

export const FlexMenu = styled(AxlFlex.Flex)`
  display: block;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const FlexBar = styled(AxlFlex.Flex)`
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
`;

export const FlexMenuSecond = styled(AxlFlex.Flex)`
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 15px 2.5px;
  text-align: right;
`;

export const ListSecond = styled.ul`
  list-style: none;
  margin: 0;
  padding: 5px 2.5px;
  text-align: right;
`;

export const Item = styled.li`
  font-family: 'AvenirNext-Italic';
  padding: 14px 25px;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  color: #4a4a4a;
`;

export const ItemSecond = styled.li`
  display: block;
  margin: 0 12.5px 10px;
  color: #848484;
  font-family: 'AvenirNext-Italic';
`;

export const LinkItem = styled.a`
  color: #4a4a4a;
  font-family: 'AvenirNext-DemiBold';
  transition: all 0.3s ease 0s;
  &:hover {
    color: #868686;
  }
  &.active {
    color: #8c83ff;
  }
`;

export const LinkLogo = styled(LinkItem)`
  line-height: 0;
`;

export const BarToggle = styled.div`
  width: 22px;
  float: right;
  position: relative;
  padding: 9px 10px;
  margin-right: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: content-box;
  cursor: pointer;
`;

export const BarIcon = styled.div`
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background-color: #563d7c;
  display: block;
  margin: 3px 0 0;
`;
