import React from 'react';
import moment from "moment";
import {FixedSizeList} from "react-window";
//Styles
import styles, * as E from './styles';
import { AxlLoading } from "../index";
import user from '../../images/svg/user.svg';

export class AxlMessageTitle extends React.Component {
  render() {
    const { topic, name, style } = this.props;
    const { assignment, unviewed_messages_count, age_in_milliseconds, section } = topic;
    const title = (topic.name && topic.name.replace(/\//g, '-')) || null;

    const minisecs  = topic.age_in_milliseconds;
    const secs  = Math.round(minisecs / 1000);
    const mins  = Math.round(secs / 60);
    const hrs   = Math.round(secs / 3600);
    const days   = Math.round(secs / 86400);
    const weeks   = Math.round(secs / 604800);
    const months   = Math.round(secs / 2592000);
    const years   = Math.round(secs / 31536000);
    // console.log(`secs: ${secs}, mins: ${mins}, hrs: ${hrs}, days: ${days}, weeks: ${weeks}, months: ${months}, years: ${years}`)
    const time = secs < 1 ? ('just now') : (
      mins < 1 ? (`${secs} secs`) : (
        hrs < 1 ? (`${mins} mins`) : (
          days < 1 ? (`${hrs} hrs`) : (
            days < 7 ? (`${days} days`) : (
              weeks < 4 ? (`${weeks} weeks`) : (
                years < 365 ? (`${months} months`) : (`${years} years`)
              )
            )
          )
        )
      )
    );
    const ignoreSections = ['ATTENDED', 'UNATTENDED', 'UNSOLVED', 'SOLVED'];
    const isDotted = ['UNATTENDED', 'UNSOLVED'];

    return <E.Message style={style} onClick={this.props.onClick} className={this.props.className}>
      <E.AccountName className={`${(unviewed_messages_count < 1 || ignoreSections.indexOf(name) !== -1) ? 'readed' : ''}`}>
        {this.props.wasFollow && <E.UserIcon src={user} alt="user" />}
        {title}
        {/*{title}{!!topic.ref_id && <E.RefText> - {topic.ref_id}</E.RefText>}*/}
      </E.AccountName>
      {unviewed_messages_count && unviewed_messages_count > 0 ? (
        ignoreSections.indexOf(name) === -1 ?
          <E.Counter>{unviewed_messages_count > 9 ? `9+` : unviewed_messages_count}</E.Counter> :
          (isDotted.indexOf(name) !== -1 ? <E.Dotted /> : null)
      ) : <div />}
      <E.Timer>{time}</E.Timer>
    </E.Message>;
  }
}

export class AxlMessageTitleGroup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: null,
      group: null,
      selected: this.props.selected
    }
  }

  componentDidMount() {
    const { group, topicSelected } = this.props;
    let groupName;
    Object.keys(group).map(name => groupName = Object(groupName, {[name]: false}));
    this.setState({
      open: groupName,
      group: group,
      selected: (topicSelected && topicSelected.id) || null
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { group, isUpdated, topicSelected } = this.props;
    if(isUpdated !== prevProps.isUpdated) {
      this.setState({group: group})
    }
    if(topicSelected && topicSelected !== prevProps.topicSelected) {
      this.setState({selected: topicSelected.id || topicSelected.ref_id});
    }
  }

  _handleSelectedTopic = (topic) => {
    this.setState({selected: topic.id || topic.ref_id});
    this.props.handleSelectedTopic(topic);
  }

  _dropdown = (name) => this.setState({open: {[name]: !this.state.open[name]}});

  _renderRow = ({ index, style, data }) => {
    const { userStore } = this.props;
    const {topics, name, selected} = data;
    const topic = topics[index];
    const wasFollow = !!(topic.unfollower_ids && userStore) && topic.unfollower_ids.indexOf(userStore.user.id) !== -1 && topic.section === 'UNATTENDED';
    const isActive = (topic && topic.id && topic.ref_id) && (topic.id === selected) || (topic.ref_id === selected)

    return <AxlMessageTitle
      style={style} key={index} topic={topic} name={name} wasFollow={wasFollow}
      className={isActive ? 'active' : ''}
      onClick={() => this._handleSelectedTopic(topic)} />
  }

  render() {
    const { showHeadCounter, isUpdated, group, userStore } = this.props;
    const { open, selected } = this.state;
    const ignoreSections = ['ATTENDED', 'UNATTENDED'];

    const topicsCounter = group && Object.values(group).map(g => g.topics.length).reduce((a, b) => (a + b)) || 0;

    if(topicsCounter) {
      return Object.keys(group).map((name, index) => !!(group[name] && group[name].topics.length) ? <E.GroupContainer key={index} style={this.props.style}>
        <E.GroupTitle>
          <E.TextGroupTitle>
            {group[name].title}
            {(showHeadCounter && group[name].unread > 0 && ignoreSections.indexOf(name) === -1) && <E.TitleCounter>
              {`${group[name].unread > 2 ?  (group[name].unread + ' Unread Chats') : (group[name].unread + ' Unread Chat')}`}
            </E.TitleCounter>}
          </E.TextGroupTitle>
          {group[name] && group[name].option && <E.GroupTitleContainer>
              <E.GroupTitleRow onClick={() => this._dropdown(name)}>
                <E.FilterTitle>{group[name].option.filter.placeHolder}</E.FilterTitle>
                <E.FilterArrow />
              </E.GroupTitleRow>
              {open && open[name] && <E.FilterContainer>
                {group[name].option.filter.options.map(f => <E.FilterName onClick={() => this.props.orderBy(name, f.value)}>{f.name}</E.FilterName>)}
              </E.FilterContainer>}
            </E.GroupTitleContainer>}
        </E.GroupTitle>
        <FixedSizeList
          height={group[name].topics.length * 40}
          itemCount={group[name].topics.length}
          itemSize={40}
          width={320}
          style={{flex: 1}}
          itemData={{
            name: name,
            selected,
            topics: group[name] && group[name].topics,
          }}>
          {this._renderRow}
        </FixedSizeList>
        {!this.props.isEndTopics[name] && <E.GroupLoadMore>
          <E.LoadMore onClick={() => this.props.loadMore(name)}>{`Load more +`}</E.LoadMore>
        </E.GroupLoadMore>}
      </E.GroupContainer> : null);
    } else {
      return <E.AxlLoadingContainer>{`No Result`}</E.AxlLoadingContainer>
    }
  }
}
