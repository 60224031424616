import React, { Component } from 'react';
import styles, {
  Container, InnerContainer, HeaderLeft, Title, HeaderRight,
  Logo, User, Avatar, NoAvatar, Name, Arrow, Dropdown, Logout,
  IconBar, MenuBar, MainMenuBar, Link
} from './styles';
import logo from './logo.svg';
import _ from 'lodash';

class AxlHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }

    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    this.goHome = this.goHome.bind(this)
  }

  goHome() {
    this.props.history.push('/')
  }

  open (e) {
    e.preventDefault()
    this.setState({ open: true }, () => document.addEventListener('click', this.close))
  }

  close() {
    this.setState({ open: false }, document.removeEventListener('click', this.close));
  }

  renderUser = () => {
    const { open } = this.state;

    return (
      <User onClick={this.open}>
        {(this.props.client && this.props.client.logo_url) ? (
          <Avatar src={this.props.logo || this.props.client.logo_url} title={this.props.client.name} width={27} />
        ) : (
          <NoAvatar />
        )}
        <Name>{(this.props.client && this.props.client.name) ? this.props.client.name : 'Anonymous'}</Name>
        <Arrow className={open ? '' : 'active'} />
        {open && <Dropdown>
          {(this.props.client && this.props.client.name) ? (
            <div>
              {this.props.children}
              <Logout onClick={() => this.props.logout && this.props.logout()} style={styles.logout}>{`Logout`}</Logout>
            </div>
          ) : (
            <Logout onClick={() => this.props.login && this.props.login()} style={styles.logout}>Login</Logout>
          )}
        </Dropdown>}
      </User>
    )
  }

  render() {
    const { open } = this.state;
    const { active } = this.props
    const theme = this.props.darkTheme ? 'dark' : 'light';
    const authenticated = this.props.user && this.props.user.name;
    const useClientLogo = JSON.parse(_.get(this, 'props.setting.client_settings.settings.use_client_logo', 'false').toLowerCase()) ? this.props.logo || _.get(this, 'props.client.logo_url', logo) : logo;

    return <Container style={{...styles.themes[theme]}}>
      <InnerContainer>
        <HeaderLeft>
          <MenuBar onClick={this.props.onClick}><IconBar /><IconBar /><IconBar /></MenuBar>
          <Logo onClick={this.goHome} src={useClientLogo} style={styles.logo} alt="logo" />
          <Title style={styles.title}>{this.props.title}</Title>
        </HeaderLeft>
        <HeaderRight>
          { this.renderUser() }
          <MainMenuBar onClick={this.props.onHandleMainMenu}><IconBar /><IconBar /><IconBar /></MainMenuBar>
        </HeaderRight>
      </InnerContainer>
    </Container>
  }
}

export default AxlHeader;
