import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { observer, inject } from 'mobx-react';
import { AppBar, Toolbar, Box, IconButton, Menu, MenuItem, Avatar, Typography, makeStyles } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LOGO from 'assets/images/svg/favico.svg';

const useStyles = makeStyles({
  appBar: {
    height: 51,
    backgroundColor: '#fff',
  },
  toolbar: {
    minHeight: 51,
    height: 51,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    textDecoration: 'none',
    color: '#000',
  },
  logo: {
    maxHeight: 50,
    objectFit: 'cover',
  },
  name: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    marginLeft: 'auto',
    cursor: 'pointer',
    color: '#8d8d8d',
  },
  avatar: {
    width: 27,
    height: 27,
    objectFit: 'cover',
  },
  menuItem: {
    minWidth: 150,
  },
});

function Header(props) {
  const classes = useStyles();

  const { userStore, onClick, logout } = props;
  const { user } = userStore;
  const name = user?.name || 'Anonymous';

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickHamburger = () => {
    if (typeof onClick === 'function') onClick();
  };

  const handleLogout = () => {
    handleCloseMenu();
    if (typeof logout === 'function') logout();
  };

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton onClick={handleClickHamburger}>
            <MenuIcon />
          </IconButton>
          <Box className={classes.logoContainer} component={Link} to="/">
            <img src={LOGO} alt="jitsu" className={classes.logo} />
            <Typography className={classes.name}>DISPATCH</Typography>
          </Box>
          <div onClick={handleOpenMenu} className={classes.actions}>
            <Typography>{name}</Typography>
            <Avatar src={user?.logo_url} className={classes.avatar} />
            {open ? <ArrowDropUpIcon color="inherit" /> : <ArrowDropDownIcon color="inherit" />}
          </div>
        </Toolbar>
      </AppBar>
      <Menu
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleLogout} className={classes.menuItem}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default compose(inject('userStore'), observer)(Header);
