import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';
import { Container, Input, Button } from './styles';

class AxlCopyLink extends Component {

  copy = (e) => {
    const {link, display} = this.props;
    this.copyToClipboard(link ? link : display);
    alert('Copied!')
  };

  copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  render () {
    const {link, display} = this.props;
    return (<ThemeProvider theme={{type: this.props.theme ? this.props.theme : 'default'}}>
      <Container>
        <Input type='text' readOnly
          value={display ? display: link} ref={(textinput) => this.textInput = textinput} />
        <Button onClick={this.copy}>
          {this.props.children}
        </Button>
      </Container>
    </ThemeProvider>)
  }
}

export default AxlCopyLink
