import React, { Component } from 'react';
import _ from 'lodash';

// Styles
import { Container, RowPanel, ColPanel } from './styles';

export default class AxlFlex extends Component {
  render() {
    const containerStyle = {
      width: this.props.fluid ? '100%' : ''
    }
    const props = _.omit(this.props, ['style', 'children'])

    return <Container {...props} style={{...this.props.style, ...containerStyle}}>{this.props.children}</Container>
  }

  static Row = class extends Component {
    render() {
      return <RowPanel {...this.props}>{this.props.children}</RowPanel>
    }
  }

  static Flex = class extends Component {
    render() {
      return <ColPanel {...this.props}>{this.props.children}</ColPanel>
    }
  }
}
