import styled from "styled-components";

export const Message = styled.div`
  display: flex;  
  padding: 10px 5px 10px 15px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  :hover {
    background-color: #FFF;
  }
  &.active {    
    background-color: #aca6f5;
  }
`;
export const AccountName = styled.div`
  text-align: left;
  flex: 1;
  margin: 0 5px;
  font-family: 'AvenirNext-Bold';
  font-size: 15px;
  font-weight: bold;
  color: #545454;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &.readed {
    font-family: 'AvenirNext-DemiBold';
    color: #696969;
    font-weight: normal;
  }
`;
export const UserIcon = styled.img`
  width: 10px;
  height: 10px;
  margin-right: 5px;
`;
export const Counter = styled.div`
  box-shadow: 0 1px 2px 0 rgba(204,204,204,0.35);
  background-color: #6c62f5;
  font-family: 'AvenirNext-Bold';
  font-size: 8px;
  font-weight: bold;
  color: #ffffff;
  padding: 3px 9px;
  margin: 0 5px;
  border-radius: 8px;
  vertical-align: middle;
  display: inline-block;
  &.readed {
    display: none;
  }
`;
export const TitleCounter = styled(Counter)`
  border-radius: 0;
`;
export const Timer = styled.div`
  font-family: 'AvenirNext';
  font-size: 11px;
  color: #000000;
  width: 50px;
  margin: 0 10px 0 5px;
  text-align: right;
`;
export const GroupContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
export const GroupTitle = styled.div`
  text-align: left;
  padding: 10px 5px 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const GroupTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
export const GroupTitleContainer = styled.div`
  position: relative;
`;
export const FilterTitle = styled.div`
  font-family: "AvenirNext-Bold";
  font-size: 13px;
  font-weight: bold;
  color: #393060;
  margin-right: 5px;
`;
export const FilterArrow = styled.span`
  display: inline-block;
  margin-top: 7px;
  border-top: 4.5px solid #393060;
  border-right: 3.5px solid transparent;
  border-bottom: 3.5px solid transparent;
  border-left: 3.5px solid transparent;
`;
export const FilterContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  border-radius: 0.5px;
  box-shadow: 0 1px 2px 0 rgba(155, 155, 155, 0.25);
  border: solid 0.3px #e4e4e4;
  background-color: #ffffff;
  padding: 3px 0;
  min-width: 130px;
  z-index: 1;
`;
export const FilterName = styled.div`
  font-family: "AvenirNext-Medium";
  font-size: 14px;
  font-weight: 500;
  color: #9b9b9b;
  padding: 5px 10px;
  border-bottom: 1px solid #DDD;
  cursor: pointer;
  text-align: center;
  :nth-last-child(1) {
    border-bottom: 0;
  }
`;
export const TextGroupTitle = styled.span`
  font-family: 'AvenirNext-Bold';
  font-size: 20px;
  font-weight: bold;
  color: #393060;
  display: inline-block;
  margin: 0 5px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
export const Dotted = styled.span`
  display: inline-block;
  width: 9px;
  height: 9px;
  background-color: #6c62f5;
  border-radius: 50%;
`;
export const GroupLoadMore = styled.div`
  text-align: center;
  margin: 10px 0;
`;
export const LoadMore = styled.div`
  border-radius: 14px;
  box-shadow: 0 1px 2px 0 rgba(155,155,155,0.23);
  border: solid 0 #979797;
  background-color: #ffffff;
  font-family: "AvenirNext-Bold";
  font-size: 9px;
  font-weight: bold;
  color: #2a2444;
  display: inline-block;
  padding: 5px 13px;
  cursor: pointer;
`;
export const MessageContainer = styled.div`
  // max-height: 200px;
  // overflow-y: scroll;
  flex: 1;
`;
export const AxlLoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const RefText = styled.i`
  font-size: 9px;
  min-width: 40px;
`;
export default {}