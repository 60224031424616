import React, { Component } from 'react';
import Flatpickr from 'react-flatpickr';
import moment from 'moment-timezone';
import { ThemeProvider } from 'styled-components';
import _ from 'lodash';
import PropTypes from 'prop-types';

// Utils
import timezones from '../../constants/Timezones'

// Components
import AxlButton from '../AxlButton';
import AxlReselect from '../AxlReselect';

// Styles
import 'flatpickr/dist/themes/material_green.css'
import { Container, Input, Icon, AxlFlatpickr, Text, PlaceHoder, SelectContainer, ButtonContainer, Error, Warning } from './styles';

export default class DateInput extends Component {
  static defaultProps = {
    displayToday: true,
  };

  constructor(props) {
    super(props);
    this.timezones = timezones.USTimeZones;
    this.state = {
      tz: null,
      defaultTimezone: 'America/Los_Angeles',
      date: props.options.defaultValue ? props.options.defaultValue : null,
      format: props.options.dateFormat ? props.options.dateFormat : 'MM/DD/YYYY'
    }
    this.onChange = this.onChange.bind(this)
    this.clearValue = this.clearValue.bind(this)
    this.onChangeTZ = this.onChangeTZ.bind(this)
    this.updateDatetimeByTimezone = this.updateDatetimeByTimezone.bind(this)
    this.fp = null;
    this.openCalenderHandler =null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.options.defaultValue !== this.props.options.defaultValue){
      this.setState({date: this.props.options.defaultValue ? this.props.options.defaultValue : null});
    }
  }

  componentDidMount() {
    // set timezone
    const that = this;
    const { tz } = that.props;
    const { defaultTimezone } = that.state;
    if(tz) {
      // Check timezone value is valid?
      const result = _.findIndex(that.timezones, function(tz) {
        return tz.value === that.props.tz
      })
      this.setState({tz: result != -1 ? this.timezones[result] : this.timezones.filter(a => a.value === defaultTimezone)[0]})
    }
  }

  componentWillUnmount() {

    if(this.openCalenderHandler) {
      clearTimeout(this.openCalenderHandler);
    }
    this.fp = null;
  }

  onChange(dateStr) {
    this.setState({date: dateStr[0]})
    this.props.onChange && this.props.onChange(dateStr[0])
  }

  onChangeTZ(tz) {
    const newTime = this.updateDatetimeByTimezone(this.state.date, tz.value)
    this.setState({tz: tz})
    this.props.onChange && this.props.onChange(newTime)
  }

  clearValue() {
    const { tz } = this.props;
    const resetTZ = {label: '', value: ''};
    const resetDate = null;

    if (tz) {
      this.setState({date: resetDate, tz: resetTZ})
    } else {
      this.setState({date: resetDate})
    }
    this.props.onChange && this.props.onChange(null)
  }

  updateDatetimeByTimezone(date, tz) {
    return new Date(moment.tz(date, 'UTC').tz(tz).format('YYYY/MM/DD HH:mm:ss'));
  }

  openCalendar = () => {
    this.openCalenderHandler = setTimeout(() => this.fp.flatpickr.open(), 0);
  }

  render () {
    const { date, format, tz, defaultTimezone } = this.state;
    const { displayToday, options, clear, children } = this.props;
    const isToday = date ? moment().startOf('day').diff(date, 'days') : null;
    const props = _.omit(this.props, ['arrow', 'displayToday', 'clear', 'tz', 'altInput', 'showError', 'error', 'warning', 'showWarning']);

    return <ThemeProvider theme={{type: this.props.theme ? this.props.theme : 'default'}}>
      <div>
        <Container>
          <Input onClick={this.openCalendar} isError={!!this.props.error} isWarning={!!this.props.warning}>
            {(displayToday && !children) && <Text>{(isToday !== null) ? (isToday === 0 ? 'today' : moment(date).format(format)) : <PlaceHoder>{this.props.options.placeHolder}</PlaceHoder>}</Text>}
            {(!displayToday && !children) && <Text>{date ? moment(date).format(format) : <PlaceHoder>{options.placeHolder}</PlaceHoder>}</Text>}
            {children && <Text><PlaceHoder>{children}</PlaceHoder></Text>}
            {this.props.arrow && <Icon />}
          </Input>

          <AxlFlatpickr
            {...props}
            value={this.state.date}
            onChange={ this.onChange }
            ref={fp => { this.fp = fp }} />
          {tz && <SelectContainer>
            <AxlReselect value={tz} placeholder="Timezone" onChange={this.onChangeTZ} options={this.timezones} theme={`main`} />
          </SelectContainer>}
          {clear && <ButtonContainer>
            <AxlButton tiny bg={`icon`} ico={{className: 'fa fa-times', style: {fontSize: 14, color: 'gray'}}} onClick={this.clearValue} />
          </ButtonContainer>}
        </Container>
        { this.props.error && this.props.showError && <Error>{this.props.error}</Error> }
        { this.props.warning && this.props.showWarning && <Warning>{this.props.warning}</Warning> }
      </div>
    </ThemeProvider>
  }
}

DateInput.propTypes = {
  displayToday: PropTypes.bool,
  options: PropTypes.shape({
    placeHolder: PropTypes.string.isRequired
  }),
  onChange: PropTypes.func,
  showError: PropTypes.bool,
  showWarning: PropTypes.bool
};

DateInput.defaultProps = {
  showError: true,
  showWarning: true
}
