import React, { Component } from 'react';
import _ from 'lodash';

// Styles
import { Container, InnerContainer, UnderList, List, BackgroundMenu, Link } from './styles'

class AxlSlideMenu extends Component {
  render() {
    const props = _.omit(this.props, ['items', 'current']);

    return <Container {...props}>
      <InnerContainer>
        <UnderList>
          {this.props.items && this.props.items.map((item, index) => !!_.intersection(item.roles, this.props.roles).length && <List key={index} className={item.value === this.props.current ? 'active' : ''}>
            <Link href={item.path || '/'} target={`_blank`}>{item.name}</Link>
          </List>)}
        </UnderList>
      </InnerContainer>
    </Container>
  }

  static AxlSlideBg = class extends Component {
    render () {
        return <BackgroundMenu {...this.props} />
      }
  }
}

export default AxlSlideMenu
