import React, { Component } from 'react';
import AxlLoading from '../AxlLoading';
import styles, { Container, Button, Icon, Image } from './styles';
import PropTypes from 'prop-types';

class AxlButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    }
  }

  onMouseOver = () => this.setState({hover: true})

  onMouseOut = () => this.setState({hover: false})

  render () {
    const { hover } = this.state;
    const { ico, source, loading, shadow, disabled, loadingColor } = this.props;
    const buttonStyleIcon = (ico || source) ? styles.button.defaultWithIcon : {};
    const buttonStyle = disabled ? styles.button.disabled : (this.props.bg ? styles.button[this.props.bg] : styles.button.default);
    const containerStyle = this.props.tiny ? styles.styles.tiny : (this.props.compact ? styles.styles.compact : styles.styles.default);
    const sizeStyle = this.props.tiny ? styles.button.tiny : (this.props.compact ? styles.button.compact : {});
    const roundedStyle = this.props.circular ? { borderRadius: this.props.tiny ? '14px' : this.props.compact ? '18px' : '24px' } : {}
    const shadowStyle = shadow ? ((typeof shadow !== 'boolean') ? styles.shadow[shadow] : styles.shadow.default) : styles.shadow.none;
    const hoverStyles = (hover && !disabled) ? (this.props.bg ? styles.button[this.props.bg][':hover'] : styles.button.default[':hover']) : {}
    const fluidStyle = { display: this.props.block ? 'block' : 'inline-block' }
    const blockStyle = { width: '100%' }
    const style = {
      ...buttonStyle,
      ...sizeStyle,
      ...roundedStyle,
      ...buttonStyleIcon,
      ...blockStyle,
      ...shadowStyle,
      ...hoverStyles
    };

    return <Container style={{ ...containerStyle, ...fluidStyle, ...this.props.style }}>
      <Button
        title={this.props.title}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        onClick = {() => { (this.props.onClick && !this.props.disabled) && this.props.onClick()}}
        style={style}>
        {ico && !source && !loading && <Icon {...ico} />}
        {source && !loading && <Image src={source} />}
        {!loading && this.props.children}
        {loading && <AxlLoading size={13} thin={1} color={loadingColor} style={{margin: 'auto', display: 'inline-block'}}/>}
      </Button>
    </Container>
  }
}

export default AxlButton

AxlButton.defaultProps = {
  loadingColor: '#FFF',
  shadow: 'none',
  title: ''
}

AxlButton.propTypes = {
  loadingColor: PropTypes.string,
  shadow: PropTypes.string
}
